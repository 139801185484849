export enum PaymentMethodsBE {
  CardPresent = 'cardpresent',
  GooglePay = 'google-pay',
  ApplePay = 'apple-pay',
  CardNotPresent = 'cardnotpresent',
  Card = 'card',
}

export enum PaymentMethods {
  card = 'card',
  google_pay = 'google-pay',
  apple_pay = 'apple-pay',
  gift_card = 'gift_card',
  giftcard = 'gift-card',
}

export enum PaymentMethodsType {
  PaymentDetails,
  CompletePayment,
}

<rs-common-order-item
  *ngIf="orderItem"
  [image]="orderItem.item.image"
  [name]="orderItem.item.customerFacingName || orderItem.item.name || ''"
  [price]="orderItem.price || 0"
  [amount]="orderItem.quantity || 1"
  [category]="orderItem.category || ''"
  [size]="orderItem.size.name || ''"
  [modifiers]="orderItem.modifiers"
  [bundleItems]="bundleItems"
  [selectedInCart]="!!orderItem.selectedInCart"
  [specialRequest]="orderItem.specialRequest || ''"
  [showCheckboxes]="false"
  [availableToChange]="false"
  [showFullDescription]="true"
  [availableToOrder]="false"
  [unavailableMessage]="unavailableMessage"
  (clickedOnItem)="clickedOnItem.emit()"
>
</rs-common-order-item>

import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Inject,
  Input,
  Output,
  PLATFORM_ID,
} from '@angular/core';
import { isPlatformServer } from '@angular/common';

import { Observable, combineLatest, filter, first } from 'rxjs';
import { Store } from '@ngxs/store';
import { isEqual } from 'lodash';

import { SharedModule } from 'src/app/_shared/_modules/shared.module';
import { RsDialogComponent } from 'src/app/_shared/_rs-design/dialog/dialog.component';
import { OrderTypeToggleComponent } from 'src/app/_shared/_modules/full-order-information/order-type-toggle/order-type-toggle.component';
import { ScheduleOrderDateComponent } from '../schedule-order-date/schedule-order-date.component';
import { ScheduleOrderTimeComponent } from '../schedule-order-time/schedule-order-time.component';

import { VenueOrderSettingsState } from 'src/app/_shared/_ngxs/venue-order-settings.state';
import { OrderDataState } from 'src/app/_shared/_ngxs/order-data.state';

import { DeliveryMethod } from 'src/app/_shared/_enums/order.enum';

import { DeliveryDataDateTime } from 'src/app/_shared/_interfaces/order.model';

import {
  ScheduleRange,
  ScheduleSettings,
} from 'src/app/_shared/_interfaces/date-settings';
import { getAvailableOrderTimeList } from '../../../_utils/opening-hours.helper';
import { untilDestroyed } from 'src/app/_shared/_utils/until-destroyed';
import { EMPTY_DELIVERY_DATE_TIME } from 'src/app/_shared/_constants/date';

@Component({
  selector: 'rs-schedule-order',
  standalone: true,
  imports: [
    SharedModule,
    RsDialogComponent,
    OrderTypeToggleComponent,
    ScheduleOrderDateComponent,
    ScheduleOrderTimeComponent,
  ],
  templateUrl: './schedule-order.component.html',
})
export class ScheduleOrderComponent {
  @Input() title!: string;

  public readonly scheduleSettings$: Observable<ScheduleSettings> =
    this.store.select(VenueOrderSettingsState.scheduleSettings);
  public readonly scheduleRange$: Observable<ScheduleRange[]> =
    this.store.select(VenueOrderSettingsState.scheduleRange);
  public readonly orderMethod$: Observable<DeliveryMethod> = this.store.select(
    OrderDataState.orderMethod
  );
  public readonly selectedDeliveryDateTime$: Observable<DeliveryDataDateTime> =
    this.store.select(OrderDataState.selectedOrderPeriod);

  public orderMethod!: DeliveryMethod;
  public scheduleSettings!: ScheduleSettings;
  public scheduleRange!: ScheduleRange[];
  public filteredScheduleRange!: ScheduleRange[];
  public selectedDay!: ScheduleRange;
  public timeList: string[] = [];
  public selectedTime: string = '';

  public readonly destroyed$ = untilDestroyed();

  @Output() selectedDayChange: EventEmitter<ScheduleRange> = new EventEmitter();
  @Output() selectedTimeChange: EventEmitter<string> = new EventEmitter();
  @Output() selectedOrderMethod: EventEmitter<DeliveryMethod> =
    new EventEmitter();

  constructor(
    @Inject(PLATFORM_ID) private readonly platformId: string,
    protected readonly changeDetectorRef: ChangeDetectorRef,
    protected readonly store: Store
  ) {}

  public ngOnInit(): void {
    if (isPlatformServer(this.platformId)) {
      return;
    }

    this.initializeSchedule();
  }

  private initializeSchedule(): void {
    this.orderMethod$
      .pipe(this.destroyed$())
      .subscribe(orderMethod => (this.orderMethod = orderMethod));

    combineLatest([this.scheduleSettings$, this.scheduleRange$])
      .pipe(
        filter(([settings, range]) => !!settings && !!range),
        first()
      )
      .subscribe(([scheduleSettings, scheduleRange]) => {
        this.scheduleSettings = scheduleSettings;
        this.scheduleRange = scheduleRange;
        this.initializeScheduleRange();
      });

    // subscribe on selected date-time
    this.selectedDeliveryDateTime$
      .pipe(this.destroyed$())
      .subscribe(selectedDeliveryDateTime => {
        if (
          !selectedDeliveryDateTime ||
          (isEqual(selectedDeliveryDateTime.day, this.selectedDay) &&
            selectedDeliveryDateTime.time === this.selectedTime) ||
          isEqual(selectedDeliveryDateTime, EMPTY_DELIVERY_DATE_TIME)
        ) {
          return;
        }

        this.selectedDay = selectedDeliveryDateTime.day;
        this.reactOnDaySelected(selectedDeliveryDateTime.day);
        this.selectedTime = selectedDeliveryDateTime.time;
      });
  }

  public initializeScheduleRange(): void {
    const isAvailableTimeToday: boolean = !!getAvailableOrderTimeList(
      this.scheduleRange[0],
      this.scheduleSettings
    ).length;

    this.filteredScheduleRange = isAvailableTimeToday
      ? [...this.scheduleRange]
      : [...this.scheduleRange].slice(1);

    this.selectedDay = this.filteredScheduleRange[0];
    this.reactOnDaySelected(this.selectedDay);
  }

  private updateTimeList(selectedDay: ScheduleRange): void {
    this.timeList = getAvailableOrderTimeList(
      selectedDay,
      this.scheduleSettings
    );
  }

  private emitChanges(): void {
    this.selectedDayChange.emit(this.selectedDay);
    this.selectedTimeChange.emit(this.selectedTime);
    this.selectedOrderMethod.emit(this.orderMethod);
  }

  public reactOnDaySelected(
    selectedDay: ScheduleRange,
    resetSelectedTime: boolean = false
  ): void {
    this.updateTimeList(selectedDay);

    (resetSelectedTime ||
      !this.selectedTime ||
      !this.timeList.includes(this.selectedTime)) &&
      (this.selectedTime = this.timeList[0] ?? '');

    this.emitChanges();
    this.changeDetectorRef.detectChanges();
  }

  public reactOnTimeSelect(time: string): void {
    this.selectedTime = time;

    this.emitChanges();
  }

  public selectDefaultSchedule(): void {
    this.reactOnDaySelected(this.filteredScheduleRange[0], true);
  }
}

export enum CommonIcons {
  Add = 'icon.add',
  AddOutline = 'icon.outline.add',
  AngleDown = 'icon.outline.angle_down',
  Back = 'icon.outline.angle-left',
  Bag = 'icon.bold.bag',
  Book = 'icon.bold.book',
  Bookmark = 'icon.bold.bookmark',
  BurgerMenu = 'icon.burger.menu',
  Checkmark = 'icon.checkmark',
  Clear = 'icon.clear',
  ClearBold = 'icon.bold.clear',
  Clock = 'icon.bold.clock',
  Close = 'icon.outline.close',
  Curbside = 'curbside_1',
  DefaultFood = 'icon.bold.cutlery',
  Delivery = 'icon.bold.delivery',
  DineIn = 'icon.bold.cutlery',
  Dollar = 'icon.outline.dollar',
  Edit = 'icon.bold.edit',
  Export = 'icon.outline.export',
  Error = 'icon.two_tone.alert',
  Flag = 'flag',
  Gift = 'icon.bold.gift_box',
  Heart = 'icon.bold.heart',
  HeartOutline = 'icon.outline.heart',
  Hide = 'icon.bold.hide',
  Information = 'icon.two_tone.info',
  InformationInCircle = 'icon.bold.info',
  Language = 'icon.bold.languages',
  Loaging = 'icon.outline.flip',
  Location = 'icon.bold.pin',
  Lock = 'icon.bold.lock',
  Mail = 'icon.bold.mail',
  Map = 'icon.bold.map',
  Mastercard = 'Mastercard',
  Next = 'icon.outline.next',
  NoItemsFound = 'icon.illustration.no_result',
  Orders = 'icon.bold.orders',
  Search = 'icon.search',
  Settings = 'icon.outline.settings',
  Show = 'icon.bold.show',
  Star = 'icon.bold.star',
  Success = 'icon.two_tone.success',
  Takeout = 'icon.bold.takeout',
  Trash = 'icon.bold.trash',
  User = 'icon.bold.user',
  Wallet = 'icon.bold.wallet',
  Warning = 'icon.two_tone.warning',
  ArrowBack = 'icon.outline.back_2',
  Cocktail = 'icon.bold.cocktail',
  Drink = 'icon.bold.drink',
  Meal = 'icon.bold.meal',
  Pizza = 'icon.bold.pizza',
  Bag2 = 'icon.bold.bag_2',
}

export enum PaymentIcons {
  Mastercard = 'Mastercard',
  Visa = 'Visa',
  Amex = 'Amex',
  JCB = 'JCB',
  DinersClub = 'Diners_Club',
  Discover = 'Discover',
  ApplePay = 'Apple_Pay',
  GooglePay = 'Google_Pay',
  PayPal = 'Paypal',
  Default = 'Default',
  GiftCard = 'Gift_Card',
}

import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Output,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatButtonToggleChange } from '@angular/material/button-toggle';
import { AbstractControl, FormBuilder } from '@angular/forms';

import { Store } from '@ngxs/store';
import {
  combineLatest,
  filter,
  first,
  map,
  Observable,
  withLatestFrom,
} from 'rxjs';

import { SelectGiftCardDialogComponent } from '../select-gift-card-dialog/select-gift-card-dialog.component';
import { CalculateRemainingTotalAfterGiftCardsPipe } from 'src/app/_shared/_pipes/calc-tips.pipe';

import { SessionState } from 'src/app/_shared/_ngxs/authentication.state';
import { CartState } from 'src/app/_shared/_ngxs/cart.state';
import { OrderDataState } from 'src/app/_shared/_ngxs/order-data.state';
import { ProfileState } from 'src/app/_shared/_ngxs/profile.state';
import { VenueOrderSettingsState } from 'src/app/_shared/_ngxs/venue-order-settings.state';
import {
  ClearSelectedGiftCardError,
  GiftCardDetails,
  SaveGuestPaymentCard,
  SavePaymentCard,
  SelectCardForOrder,
  SelectGiftCardForOrder,
  SetCompletePaymentMethod,
  SetIsGiftCardCoverTotal,
  SetPaymentMethod,
} from 'src/app/_shared/_ngxs/cart.actions';
import {
  OpenAddNewCardDialog,
  OpenDialog,
  OpenSelectCardForPaymentDialog,
} from 'src/app/_shared/_ngxs/dialog.actions';
import { GetProfileGiftCards } from 'src/app/_shared/_ngxs/profile.actions';

import { untilDestroyed } from 'src/app/_shared/_utils/until-destroyed';

import { MatDialogId } from 'src/app/_shared/_enums/mat-dialog-id.enum';
import {
  PaymentMethods,
  PaymentMethodsType,
} from 'src/app/_shared/_enums/payment-methods.enum';
import { CardBrand } from 'src/app/_shared/_enums/card.enum';
import { OrderItemType } from 'src/app/_shared/_enums/order-item-type.enum';

import { Card } from 'src/app/profile/_interfaces/payment.model';
import { AddressForm } from 'src/app/_shared/_interfaces/address.model';
import { GuestUserData } from 'src/app/_shared/_interfaces/session.model';
import { PaymentViewDescription } from 'src/app/_shared/_interfaces/payment.model';
import { GiftCard } from 'src/app/_shared/_interfaces/gift-card.model';
import { Tips } from 'src/app/_shared/_interfaces/item.model';
import { NewCardToAdd } from 'src/app/_shared/_models/common.interface';

import { TEST_ID } from 'src/app/_shared/_constants/e2e-ids.constants';

import {
  getAdditionalAvailablePaymentOptions,
  getAvailablePaymentOptions,
} from 'src/app/_shared/_utils/payment.utils';
import { copy } from 'src/app/_shared/_utils/common';

// TODO need to refactor logic here to make it similar as
// we have for <rs-paying-with>
@Component({
  selector: 'rs-pay-with',
  templateUrl: 'pay-with.component.html',
  styleUrls: ['pay-with.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [CalculateRemainingTotalAfterGiftCardsPipe],
})
export class PayWithComponent {
  public readonly cards$: Observable<Card[]> = this.store.select(
    ProfileState.cards
  );
  public readonly isLoggedIn$ = this.store.select(SessionState.isLoggedIn);
  public readonly selectedPaymentMethod$ = this.store.select(
    CartState.paymentMethod
  );
  public readonly selectedGiftCard$ = this.store.select(
    CartState.selectedGiftCard
  );
  public readonly selectedGiftCardError$ = this.store.select(
    CartState.selectedGiftCardError
  );
  public readonly giftCards$: Observable<GiftCard[]> = this.store.select(
    ProfileState.giftCards
  );
  public readonly guestUserData$: Observable<GuestUserData> = this.store.select(
    CartState.guestUserData
  );
  public readonly selectedPaymentCard$ = this.store.select(
    CartState.selectedPaymentCard
  );
  private readonly tips$: Observable<Tips> = this.store.select(CartState.tips);
  private readonly cartFilling$: Observable<{
    cartItemsType: OrderItemType | null;
  }> = this.store.select(CartState.cartFilling);
  public isLoggedIn = this.store.selectSnapshot(SessionState.isLoggedIn);

  public get giftCardCodeControl(): AbstractControl | null {
    return this.giftCardForm.controls['giftCardCode'];
  }

  public cards: Card[] = [];
  public selectedCard: Card | null = null;
  public giftCards: GiftCard[] = [];
  public paymentMethods = PaymentMethods;
  public selectedPaymentMethod = PaymentMethods.card;
  public completePaymentMethod = PaymentMethods.card;
  public availablePaymentMethods: PaymentViewDescription[] = [];
  public additionalAvailablePaymentMethods: PaymentViewDescription[] = [];
  public isShowAdditionalPayment: boolean = false;
  public giftCardForm = this.formBuilder.group({
    giftCardCode: '',
  });
  public readonly PaymentMethodsType = PaymentMethodsType;
  public readonly id = TEST_ID;
  private readonly destroyed$ = untilDestroyed();

  @Output() paymentMethodChanged = new EventEmitter<PaymentMethods>();

  constructor(
    private readonly dialog: MatDialog,
    private readonly store: Store,
    private readonly formBuilder: FormBuilder,
    private readonly changeDetectorRef: ChangeDetectorRef,
    private readonly calculateRemainingTotalAfterGiftCardPipe: CalculateRemainingTotalAfterGiftCardsPipe
  ) {}

  ngOnInit(): void {
    this.initializeSubscriptions();
    this.clearGiftCardError();
  }

  public onPaymentToggleChange({
    value: buttonToggleValue,
  }: MatButtonToggleChange): void {
    switch (true) {
      case buttonToggleValue === PaymentMethods.gift_card:
        this.store.dispatch([
          new SetPaymentMethod(PaymentMethods.gift_card),
          new SelectGiftCardForOrder(this.giftCards[0]),
        ]);
        break;
      default:
        this.removeGiftCard();
        this.store.dispatch(new SetPaymentMethod(buttonToggleValue));
        break;
    }

    this.paymentMethodChanged.emit(buttonToggleValue);
  }

  public onCompletePaymentToggleChange(
    buttonToggle: MatButtonToggleChange
  ): void {
    this.completePaymentMethod = buttonToggle.value;
    this.store.dispatch(
      new SetCompletePaymentMethod(this.completePaymentMethod)
    );
  }

  public clearGiftCardSelection(): void {
    this.store.dispatch(new SelectGiftCardForOrder(null));
  }

  public addPaymentCard(): void {
    if (!this.isLoggedIn || !this.cards.length) {
      const deliveryAddress = this.store.selectSnapshot(
        OrderDataState.orderAddress
      );

      this.store.dispatch(
        new OpenAddNewCardDialog(
          (deliveryAddress as AddressForm) || null,
          this.selectedCard
        )
      );
    } else {
      this.store
        .dispatch(new OpenSelectCardForPaymentDialog())
        .pipe(first())
        .subscribe(() => {
          this.dialog
            .getDialogById(MatDialogId.select_payment_card)
            ?.afterClosed()
            .pipe(
              first(),
              filter((card: Card) => !!card)
            )
            .subscribe(card =>
              this.store.dispatch(new SelectCardForOrder(card))
            );
        });
    }
  }

  private initializeSubscriptions(): void {
    this.isLoggedIn$
      .pipe(this.destroyed$())
      .subscribe((isLoggedIn: boolean) => {
        this.isLoggedIn = isLoggedIn;

        isLoggedIn && this.store.dispatch(new GetProfileGiftCards());
      });

    this.selectedPaymentMethod$
      .pipe(first(paymentMethod => !!paymentMethod))
      .subscribe((paymentMethod: PaymentMethods) =>
        this.paymentMethodChanged.emit(paymentMethod)
      );

    this.selectedPaymentMethod$
      .pipe(this.destroyed$())
      .subscribe(
        (paymentMethod: PaymentMethods) =>
          (this.selectedPaymentMethod = paymentMethod)
      );

    this.store
      .select(VenueOrderSettingsState.availablePaymentMethods)
      .pipe(first(availablePaymentMethods => !!availablePaymentMethods))
      .subscribe((availablePaymentMethods: PaymentMethods[]) => {
        const selectedPaymentMethod: PaymentMethods = copy(
          this.store.selectSnapshot(CartState.paymentMethod)
        );

        this.availablePaymentMethods = getAvailablePaymentOptions(
          availablePaymentMethods
        );
        this.additionalAvailablePaymentMethods =
          getAdditionalAvailablePaymentOptions(availablePaymentMethods);
        this.availablePaymentMethods.length &&
          !availablePaymentMethods.includes(selectedPaymentMethod) &&
          this.store.dispatch(
            new SetPaymentMethod(this.availablePaymentMethods[0].placeholder)
          );
        this.changeDetectorRef.detectChanges();
      });

    combineLatest([this.isLoggedIn$, this.cards$])
      .pipe(
        filter(([isLoggedIn, _]) => !!isLoggedIn),
        map(([_, cards]: [boolean, Card[]]) => cards),
        this.destroyed$()
      )
      .subscribe((cards: Card[] | undefined) => {
        this.cards = cards ?? [];

        const defaultCard =
          this.cards.find(creditCard => creditCard.default)! ||
          (this.cards.length ? this.cards[0] : null);

        this.store.dispatch(new SelectCardForOrder(defaultCard));
        this.selectedCard = defaultCard;
        this.changeDetectorRef.detectChanges();
      });

    combineLatest([this.isLoggedIn$, this.selectedPaymentCard$])
      .pipe(
        filter(([isLoggedIn, _]: [boolean, Card | null]) => !!isLoggedIn),
        map(([_, card]: [boolean, Card | null]) => card),
        this.destroyed$()
      )
      .subscribe((card: Card | null) => {
        setTimeout(() => {
          this.selectedCard = card;
          this.changeDetectorRef.detectChanges();
        });
      });

    combineLatest([this.isLoggedIn$, this.giftCards$])
      .pipe(
        filter(([isLoggedIn, _]: [boolean, GiftCard[]]) => !!isLoggedIn),
        map(([_, giftCards]: [boolean, GiftCard[]]) => giftCards),
        this.destroyed$()
      )
      .subscribe(
        giftCards =>
          (this.giftCards = giftCards.filter(giftCard => !!giftCard) || [])
      );

    combineLatest([this.isLoggedIn$, this.guestUserData$])
      .pipe(
        filter(([isLoggedIn, _]: [boolean, GuestUserData]) => !isLoggedIn),
        map(([_, guestUserData]: [boolean, GuestUserData]) => guestUserData),
        filter(({ isCardValid }: GuestUserData) => isCardValid),
        map(({ card }: GuestUserData) => card),
        this.destroyed$()
      )
      .subscribe((card: NewCardToAdd) => {
        this.selectedCard = { ...card, cardBrand: CardBrand.Unknown };
        this.changeDetectorRef.detectChanges();
      });

    this.tips$
      .pipe(this.destroyed$())
      .subscribe(() => this.changeDetectorRef.markForCheck());

    this.selectedGiftCard$
      .pipe(
        this.destroyed$(),
        withLatestFrom(this.cartFilling$),
        filter(([giftCard, _]) => !!giftCard)
      )
      .subscribe(
        ([giftCard, cartFilling]) =>
          (this.isShowAdditionalPayment =
            cartFilling.cartItemsType !== OrderItemType.gift_card &&
            this.checkRemainingTotalAfterGiftCard(giftCard as GiftCard))
      );
  }

  public handleNewPaymentCard(cardDetails: NewCardToAdd): void {
    this.store.dispatch(
      new (this.isLoggedIn ? SavePaymentCard : SaveGuestPaymentCard)(
        cardDetails
      )
    );
  }

  public scanGiftCardQRCode(): void {
    this.store
      .dispatch(
        new OpenDialog(SelectGiftCardDialogComponent, {
          id: MatDialogId.select_gift_card_payment,
          autoFocus: false,
        })
      )
      .pipe(first())
      .subscribe(() => {
        this.dialog
          .getDialogById(MatDialogId.select_gift_card_payment)
          ?.afterClosed()
          .pipe(
            first(),
            filter(giftCardCode => !!giftCardCode)
          )
          .subscribe((giftCardCode: string) =>
            this.setGiftCardForCheckout(giftCardCode)
          );
      });
  }

  public setGiftCardForCheckout(giftCardCode: string): void {
    this.store.dispatch(new GiftCardDetails(giftCardCode));
    this.giftCardForm.reset();
    this.clearGiftCardError();
  }

  private checkRemainingTotalAfterGiftCard(
    selectedGiftCard: GiftCard
  ): boolean {
    const isRemainingTotalAfterGiftCard =
      this.calculateRemainingTotalAfterGiftCardPipe.transform(
        selectedGiftCard.currentBalance!
      ) > 0;

    this.store.dispatch(
      new SetIsGiftCardCoverTotal(!isRemainingTotalAfterGiftCard)
    );

    isRemainingTotalAfterGiftCard &&
      this.store.dispatch(
        new SetCompletePaymentMethod(this.completePaymentMethod)
      );

    return isRemainingTotalAfterGiftCard;
  }

  private removeGiftCard(): void {
    if (this.selectedPaymentMethod !== PaymentMethods.gift_card) {
      return;
    }

    this.clearGiftCardSelection();
    this.clearGiftCardError();
  }

  public clearGiftCardError(): void {
    this.store.dispatch(new ClearSelectedGiftCardError());
  }
}

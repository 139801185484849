import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';

import { SharedModule } from 'src/app/_shared/_modules/shared.module';

import { TIME_WITH_ASAP_TRANSLATIONS_PATHS } from 'src/app/_shared/_constants/paths-to-translations.constant';

@Component({
  selector: 'rs-schedule-order-time',
  standalone: true,
  imports: [SharedModule],
  templateUrl: './schedule-order-time.component.html',
  styleUrls: ['./schedule-order-time.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ScheduleOrderTimeComponent {
  @Input() title!: string;
  @Input() timeList!: string[];
  @Input() selectedTime!: string;

  public readonly translationsPaths = TIME_WITH_ASAP_TRANSLATIONS_PATHS;

  @Output() selectedTimeChange: EventEmitter<string> =
    new EventEmitter<string>();

  public selectTime(time: string): void {
    this.selectedTime = time;

    this.selectedTimeChange.emit(time);
  }
}

import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { Store } from '@ngxs/store';
import { Observable } from 'rxjs';

import { SharedModule } from '../../_modules/shared.module';
import { RsFullHeightDialogComponent } from '../../_rs-design/dialog/full-height-dialog.component';
import { PayingWithComponent } from '../paying-with/paying-with.component';
import { ContactInfoNotificationComponent } from '../contact-info-notification/contact-info-notification.component';

import { OrderDataState } from '../../_ngxs/order-data.state';
import { VenueState } from '../../_ngxs/venue.state';
import { OrderState } from '../../_ngxs/order.state';
import { CartState } from '../../_ngxs/cart.state';
import { InitializeCheckout } from '../../_ngxs/cart.actions';
import { SetIsOpenTab } from '../../_ngxs/order.actions';

import { CommonIcons } from '../../_enums/digital-storefront-icons.enum';
import { OrderType } from '../../_enums/order.enum';

import { TEST_ID } from '../../_constants/e2e-ids.constants';
import { NEW_ICONS_DIRECTORY } from '../../_constants/digital-storefront.constants';

import { SvgIconService } from 'src/app/_services/svg-icon.service';

@Component({
  selector: 'rs-cart-dialog-main-view',
  standalone: true,
  imports: [
    SharedModule,
    RsFullHeightDialogComponent,
    PayingWithComponent,
    ContactInfoNotificationComponent,
  ],
  templateUrl: './cart-dialog-main-view.component.html',
  styleUrls: ['./cart-dialog-main-view.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CartDialogMainViewComponent {
  @Input() isLoggedIn!: boolean | null;
  @Input() isShadowUser!: boolean | null;
  @Input() checkoutButtonDisabled: boolean = false;
  @Input() isGiftCardInCart: boolean = false;

  public readonly badgeNumber$: Observable<string> = this.store.select(
    CartState.badgeNumber
  );
  public readonly price$: Observable<number> = this.store.select(
    CartState.overallPrice
  );
  public readonly isOpenTab: boolean = this.store.selectSnapshot(
    OrderState.isOpenTab
  );
  public readonly allowOpenTab: boolean = this.store.selectSnapshot(
    VenueState.allowOpenTab
  );
  public readonly orderType$ = this.store.select(OrderDataState.orderType);
  public readonly selectedOrderPeriod$ = this.store.select(
    OrderDataState.selectedOrderPeriod
  );

  public isPaymentSelected!: boolean;

  public readonly backIcon = CommonIcons.ArrowBack;
  public readonly dineInOrder = OrderType.DineIn;
  public readonly id = TEST_ID;

  constructor(
    private readonly store: Store,
    private readonly svgIconService: SvgIconService
  ) {}

  ngOnInit(): void {
    this.registerIcons();
  }

  private registerIcons(): void {
    this.svgIconService.registerSvgIcons([this.backIcon], NEW_ICONS_DIRECTORY);
  }

  public goToCheckout(): void {
    this.store.dispatch(new InitializeCheckout());
  }

  public openTab(): void {
    this.store.dispatch([new SetIsOpenTab(true), new InitializeCheckout()]);
  }
}

<div class="rs-payment-selection">
  <rs-banner-alert
    rs-status="error"
    rs-rounded="true"
    *ngIf="!selectedPaymentMethod"
    class="mb-2 d-block"
  >
    {{ 'PAYING_WITH.Select payment warning' | translate }}
  </rs-banner-alert>

  <rs-banner-alert
    rs-status="error"
    rs-rounded="true"
    *ngIf="
      selectedPaymentMethod === paymentMethods.gift_card &&
      isShowAdditionalPayment &&
      !completePaymentMethod
    "
    class="mb-2 d-block"
  >
    {{ 'PAYING_WITH.Select additional payment method' | translate }}
  </rs-banner-alert>

  <ul class="m-0">
    <ng-template
      [ngIf]="
        paymentMethods.gift_card
          | rsIsAvailablePaymentType: availablePaymentMethods
      "
    >
      <rs-list-item-segment
        *ngFor="let card of giftCards$ | async; let index = index"
      >
        <mat-icon [svgIcon]="'Gift_Card'"></mat-icon>
        <rs-list-item-segment-label>
          {{
            'PAYING_WITH.ending in XXXX'
              | translate: { data: card.giftCardNumber | rsCardLastFourDigits }
          }}
          <rs-list-item-segment-hint>
            {{
              'PAYING_WITH.Balance {balance}'
                | translate: { balance: (card.currentBalance || 0) / 100 }
            }}
          </rs-list-item-segment-hint>
        </rs-list-item-segment-label>
        <rs-list-item-segment-action>
          <mat-checkbox
            [checked]="selectedPaymentMethod === paymentMethods.gift_card"
            (click)="$event.stopPropagation()"
            (change)="selectPayment($event, paymentMethods.gift_card)"
          ></mat-checkbox>
        </rs-list-item-segment-action>
      </rs-list-item-segment>
    </ng-template>

    <ng-template
      [ngIf]="
        paymentMethods.card | rsIsAvailablePaymentType: availablePaymentMethods
      "
    >
      <rs-list-item-segment
        *ngFor="let card of cards$ | async; let index = index"
        [active]="getActivePaymentState(card)"
      >
        <mat-icon
          [svgIcon]="
            card.cardBrand
              ? (card.cardBrand | lowercase | titlecase).trim()
              : 'Default'
          "
        ></mat-icon>
        <rs-list-item-segment-label>
          {{
            'PAYING_WITH.ending in XXXX'
              | translate
                : { data: card.redactedCardNumber | rsCardLastFourDigits }
          }}
        </rs-list-item-segment-label>
        <rs-list-item-segment-action>
          <mat-checkbox
            (click)="$event.stopPropagation()"
            (change)="selectCardForOrder($event, card)"
            [checked]="getActivePaymentState(card)"
          ></mat-checkbox>
        </rs-list-item-segment-action>
      </rs-list-item-segment>
    </ng-template>

    <ng-template
      [ngIf]="
        paymentMethods.google_pay
          | rsIsAvailablePaymentType: availablePaymentMethods
      "
    >
      <rs-list-item-segment>
        <mat-icon [svgIcon]="'Google_Pay'"></mat-icon>
        <rs-list-item-segment-label>
          {{ 'PAY_NOW.google-pay' | translate }}
        </rs-list-item-segment-label>
        <rs-list-item-segment-action>
          <mat-checkbox
            [checked]="
              selectedPaymentMethod === paymentMethods.google_pay ||
              (selectedPaymentMethod === paymentMethods.gift_card &&
                completePaymentMethod === paymentMethods.google_pay)
            "
            (click)="$event.stopPropagation()"
            (change)="selectPayment($event, paymentMethods.google_pay)"
          ></mat-checkbox>
        </rs-list-item-segment-action>
      </rs-list-item-segment>
    </ng-template>

    <ng-template
      [ngIf]="
        supportApplePay &&
        (paymentMethods.apple_pay
          | rsIsAvailablePaymentType: availablePaymentMethods)
      "
    >
      <rs-list-item-segment>
        <mat-icon [svgIcon]="'Apple_Pay'"></mat-icon>
        <rs-list-item-segment-label>
          {{ 'PAY_NOW.apple-pay' | translate }}
        </rs-list-item-segment-label>
        <rs-list-item-segment-action>
          <mat-checkbox
            [checked]="
              selectedPaymentMethod === paymentMethods.apple_pay ||
              (selectedPaymentMethod === paymentMethods.gift_card &&
                completePaymentMethod === paymentMethods.apple_pay)
            "
            (click)="$event.stopPropagation()"
            (change)="selectPayment($event, paymentMethods.apple_pay)"
          ></mat-checkbox>
        </rs-list-item-segment-action>
      </rs-list-item-segment>
    </ng-template>
  </ul>
</div>

import { Injectable, NgZone } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';

import { catchError, finalize, of, tap, first } from 'rxjs';
import {
  Action,
  Actions,
  Selector,
  State,
  StateContext,
  Store,
  createSelector,
  ofActionCompleted,
} from '@ngxs/store';

import * as moment from 'moment';
import { cloneDeep, isEqual, omit } from 'lodash';
import { TranslateService } from '@ngx-translate/core';
import { v4 as uuid } from 'uuid';

import {
  AddGiftCard,
  AddToCart,
  CalculateCheck,
  CalculatePrices,
  ClearCart,
  ClearGuestUserData,
  ClearItems,
  CreateDeliveryQuote,
  DeleteItem,
  GiftCardDetails,
  MergeGiftCard,
  RemoveItemFromCart,
  SaveGuestPaymentCard,
  SavePaymentCard,
  SelectCardForOrder,
  SelectGiftCardForOrder,
  CalculateCheckAndCreateDeliveryQuote,
  SetGuestUserPhoneData,
  SetGuestUserData,
  SetItems,
  SetMenuId,
  SetOverallPrice,
  SetTableId,
  SetTableName,
  SetTableSectionId,
  SetTips,
  SetPaymentMethod,
  SetCompletePaymentMethod,
  ClearSelectedGiftCardError,
  ClearGiftCards,
  SetIsGiftCardCoverTotal,
  ResetTips,
  SetTranslations,
  SetCartEditing,
  SetGiftCards,
  SetIsSearchingDriver,
  ResetCheckCalculations,
  InitializeCheckout,
  SetCheckCalculations,
  ClearSenderDataOfGiftCards,
  AssignSenderToGiftCard,
} from './cart.actions';
import { OpenPayNowDialog } from './dialog.actions';
import { InitializePaymentMethods } from './profile.actions';
import { ClearOrderDataPartAfterLogout } from './order-data.actions';

import { HideSpinner, ShowSpinner } from '../_components/spinner/spinner.state';
import { VenueState } from 'src/app/_shared/_ngxs/venue.state';
import { SessionState } from './authentication.state';
import { ProfileState } from './profile.state';
import { OrderDataState } from './order-data.state';

import { TaxRules } from 'src/app/_shared/_enums/item.enum';
import { DeliveryMethod, DeliveryType } from '../_enums/order.enum';
import { OrderItemType } from '../_enums/order-item-type.enum';
import { DeliveryMethodQuoteCreationErrorCode } from '../_enums/quote-errors-codes.enum';
import { PaymentMethods } from '../_enums/payment-methods.enum';
import { SessionStorageKeys } from '../_enums/session-storage-keys.enum';
import { MatDialogId } from '../_enums/mat-dialog-id.enum';
import { ItemCategory } from '../_enums/item-category.enum';

import {
  CalculatedItem,
  CalculateItemsPriceRequest,
  ItemsForCalculation,
  StateItem,
  SelectedModifier,
  ModifierCalculatePriceRequest,
  Money,
  Tips,
} from '../_interfaces/item.model';
import {
  CheckCalculation,
  CheckTax,
} from 'src/app/_shared/_interfaces/check-calculation.model';
import { GiftCard } from '../_interfaces/gift-card.model';
import {
  DeliveryData,
  DeliveryDataDateTime,
  QuoteSearchingDriver,
} from '../_interfaces/order.model';
import { Card } from 'src/app/profile/_interfaces/payment.model';
import { AddressForm } from '../_interfaces/address.model';
import { Profile } from 'src/app/profile/_interfaces/profile.model';
import {
  CreateQuoteRequestBody,
  DeliveryWithGiftCardData,
  Quote,
} from '../_interfaces/delivery.model';
import {
  GuestUserData,
  GuestUserValidInfoData,
} from '../_interfaces/session.model';
import { GiftCardInForSelection } from '../_components/list-of-items-in-order/models';
import { Menu } from '../_interfaces/menu.model';
import { UserProfileAddress } from 'src/app/profile/_interfaces/address.model';

import { NewCardToAdd } from 'src/app/_shared/_models/common.interface';

import { getHoursAndMinutesFromInterval } from '../_constants/date';

import { copy, getAmountObject } from 'src/app/_shared/_utils/common';
import { isCarValid } from '../_utils/car.utils';
import { cleanObject } from '../_utils/clean-object.helper';
import {
  getFilteredCartItems,
  isAvailableMenuForCurrentOrderType,
  isAvailableMenuItemForSelectedTime,
} from '../_utils/menus';

import { CatalogService } from 'src/app/_services/catalog.service';
import { PaymentService } from 'src/app/_services/payment.service';
import { DeliveryService } from 'src/app/_services/delivery.service';
import { NotificationService } from '../_services/notification.service';
import { SessionStorageEngine } from 'src/app/_services/session-storage.service';

export type CartEntity = StateItem | GiftCard;

interface CartStateModel {
  items: StateItem[];
  previouslyOrderedItems: StateItem[]; // TODO: remove when sentrequest is available on BE
  tableName: string;
  menuId: string;
  check: any;
  tips: Tips;
  calculatedItem: CalculatedItem[];
  overallPrice: number;
  checkCalculations: CheckCalculation;
  tableId: string;
  sectionId: string;
  giftCards: GiftCard[];
  selectedPaymentCard: Card | null;
  selectedGiftCard: GiftCard | null;
  selectedGiftCardError: boolean;
  paymentMethod: PaymentMethods;
  venueId: string;
  notAvailableItemName: string;
  calculationError: boolean;
  quote: Quote | null;
  guestUserData: GuestUserData;
  completePaymentMethod?: PaymentMethods;
  isGiftCardCoverTotal: boolean;
  translations: { deliveryFees: string };
  isEditing: boolean;
  isSearchingDriver: boolean;
  isAddressCoveredByDelivery: boolean;
}

const DEFAULT_CHECK_CALCULATIONS = {
  currency: 'USD',
  entries: [],
  grossTotal: 0,
  netTotal: 0,
};

const DEFAULT_TIPS = {
  absolute: 0,
  percentage: 0,
  isCustom: false,
};

const DEFAULT_CART: CartStateModel = {
  items: [],
  previouslyOrderedItems: [],
  tableName: 'N/A',
  menuId: '',
  tableId: '',
  sectionId: '',
  tips: DEFAULT_TIPS,
  calculatedItem: [],
  overallPrice: 0,
  check: {
    subTotal: 0,
    fess: 0,
    tax: 0,
    tips: 0,
    total: 0,
  },
  checkCalculations: {
    currency: 'N/A',
    entries: [],
    grossTotal: 0,
    netTotal: 0,
  },
  giftCards: [],
  venueId: '',
  selectedPaymentCard: null,
  selectedGiftCard: null,
  selectedGiftCardError: false,
  notAvailableItemName: '',
  calculationError: false,
  quote: null,
  paymentMethod: PaymentMethods.card,
  completePaymentMethod: undefined,
  guestUserData: {
    isFormDataValid: false,
    isCardValid: false,
    name: '',
    phone: undefined,
    shadowUserUsername: {
      phone: undefined,
      email: '',
    },
    card: {
      tokenType: '',
      redactedCardNumber: '',
      cardNumber: '',
      cardholderName: '',
      cardExpirationDate: '',
      zipCode: '',
      address: '',
      address2: '',
      countryCode: '',
      city: '',
      state: '',
      default: false,
      cvv: '',
      cardToken: '',
    },
  },
  isGiftCardCoverTotal: false,
  translations: {
    deliveryFees: '',
  },
  isEditing: false,
  isSearchingDriver: false,
  isAddressCoveredByDelivery: false,
};

@State<CartStateModel>({
  name: 'cart',
  defaults: DEFAULT_CART,
})
@Injectable()
export class CartState {
  constructor(
    private readonly store: Store,
    private readonly catalogService: CatalogService,
    private readonly ngZone: NgZone,
    private readonly paymentService: PaymentService,
    private readonly translateService: TranslateService,
    private readonly deliveryService: DeliveryService,
    private readonly notificationService: NotificationService,
    private readonly sessionStorage: SessionStorageEngine,
    private readonly matDialog: MatDialog,
    private readonly actions$: Actions
  ) {}

  @Selector()
  static isAddressCoveredByDelivery({
    isAddressCoveredByDelivery,
  }: CartStateModel) {
    return isAddressCoveredByDelivery;
  }

  @Selector()
  static calculationError({ calculationError }: CartStateModel): boolean {
    return calculationError;
  }

  @Selector()
  static notAvailableItemName({
    notAvailableItemName,
  }: CartStateModel): string {
    return notAvailableItemName;
  }

  @Selector()
  static tableId({ tableId }: CartStateModel): string {
    return tableId;
  }

  @Selector()
  static menuId({ menuId }: CartStateModel): string {
    return menuId;
  }

  @Selector()
  static sectionId({ sectionId }: CartStateModel): string {
    return sectionId;
  }

  @Selector()
  static venueId({ venueId }: CartStateModel): string {
    return venueId;
  }

  @Selector()
  static checkCalculations({
    checkCalculations,
  }: CartStateModel): CheckCalculation {
    return checkCalculations;
  }

  @Selector()
  static quote(state: CartStateModel): Quote | null {
    return state.quote;
  }

  @Selector()
  static overallPrice(state: CartStateModel): number {
    if (state.giftCards?.length) {
      return state.giftCards
        .filter(giftCard => giftCard.selectedInCart)
        .reduce((total, giftCard) => {
          total += giftCard?.currentBalance ?? 0;

          return total;
        }, 0);
    }

    if (state.items?.length) {
      return state.overallPrice ?? 0;
    }

    return 0;
  }

  @Selector()
  static items({ items }: CartStateModel): StateItem[] {
    return items;
  }

  static item(itemId: string, cartItemId: string) {
    return createSelector([CartState], ({ items }: CartStateModel) =>
      cartItemId
        ? items.find(
            item =>
              item.item.itemId === itemId && item.cartItemId === cartItemId
          )
        : items.find(item => item.item.itemId === itemId)
    );
  }

  @Selector()
  static selectedInCartItems(state: CartStateModel): StateItem[] {
    return state.items.filter(item => item.selectedInCart);
  }

  @Selector()
  static itemsNotInCart(state: CartStateModel): number {
    return state.items.filter(item => !item.selectedInCart).length;
  }

  @Selector()
  static giftCards({ giftCards }: CartStateModel): GiftCard[] {
    return giftCards;
  }

  @Selector()
  static isGiftCardInCart({ giftCards }: CartStateModel): boolean {
    return !!giftCards?.length;
  }

  @Selector()
  static cartFilling({ items, giftCards }: CartStateModel): {
    items: CartEntity[];
    cartItemsType: OrderItemType | null;
  } {
    let cartItems: CartEntity[] = [];
    let cartItemsType: OrderItemType | null = null;

    if (items?.length) {
      cartItems = items;
      cartItemsType = OrderItemType.item;
    } else if (giftCards?.length) {
      cartItems = giftCards.map(giftCard => ({
        ...giftCard,
        item: { cartItemId: uuid() },
        quantity: 1,
      }));
      cartItemsType = OrderItemType.gift_card;
    } else {
      cartItems = [];
    }

    return {
      items: cartItems,
      cartItemsType,
    };
  }

  @Selector()
  static check(state: CartStateModel) {
    return state.check;
  }

  @Selector()
  static tips(state: CartStateModel): Tips {
    return state.tips;
  }

  @Selector()
  static tipsInCents({ tips }: CartStateModel): number {
    return tips?.absolute || 0;
  }

  @Selector()
  static tipsInPercents({ tips }: CartStateModel): number {
    return tips?.percentage || 0;
  }

  @Selector()
  static deliveryFeesTranslation({ translations }: CartStateModel): string {
    return translations.deliveryFees || '';
  }

  @Selector()
  static deliveryFees({
    translations,
    checkCalculations,
  }: CartStateModel): number {
    const deliveryFeesName = translations.deliveryFees;
    const deliveryFees =
      checkCalculations?.entries?.find(entry => entry.name === deliveryFeesName)
        ?.customerFee || 0;

    return deliveryFees || 0;
  }

  @Selector()
  static tableName(state: CartStateModel) {
    return state.tableName;
  }

  @Selector()
  static showBadge(state: CartStateModel): boolean {
    return !(state?.items?.length === 0 && state?.giftCards?.length === 0);
  }

  @Selector()
  static previouslyOrderedItems({
    previouslyOrderedItems,
  }: CartStateModel): StateItem[] {
    return previouslyOrderedItems;
  }

  @Selector()
  static badgeNumber(state: CartStateModel): string {
    if (state.items?.length) {
      return (state.items ?? [])
        .reduce((sum, { quantity }) => sum + quantity, 0)
        .toString();
    }

    if (state.giftCards?.length) {
      return state.giftCards
        ?.filter(({ selectedInCart }) => selectedInCart)
        .length.toString();
    }

    return '0';
  }

  @Selector([CartState.tipsInCents])
  static totalWithTips(
    { checkCalculations }: CartStateModel,
    tipsInCents: number
  ): number {
    return checkCalculations.netTotal + tipsInCents;
  }

  @Selector([
    OrderDataState.orderData,
    CartState.totalWithTips,
    CartState.overallPrice,
    SessionState.isLoggedIn,
    SessionState.isShadowUser,
    CartState.guestUserData,
  ])
  static isOrderReadyForPayment(
    {
      selectedPaymentCard,
      items,
      selectedGiftCard,
      giftCards,
      quote,
      paymentMethod,
      completePaymentMethod,
    }: CartStateModel,
    deliveryData: DeliveryData,
    totalWithTips: number,
    overallPrice: number,
    isLoggedIn: boolean,
    isShadowUser: boolean,
    guestUserData: GuestUserData
  ): boolean {
    const selectedInCartGiftCards: GiftCard[] = giftCards?.filter(
      ({ selectedInCart }) => selectedInCart
    );
    switch (true) {
      case !!selectedInCartGiftCards.length: {
        return CartState.isPayButtonEnabledWhenBuyingGiftCards(
          selectedInCartGiftCards,
          selectedGiftCard,
          paymentMethod
        );
      }
      case deliveryData?.orderType === DeliveryMethod.Delivery && !quote:
      case CartState.isCurbsideDeliveryTypeDataIncorrect(deliveryData): {
        return false;
      }
      case deliveryData?.orderType === DeliveryMethod.Delivery &&
        !deliveryData.data.asap &&
        !deliveryData.data.time: {
        return false;
      }
      case CartState.isGuestUserInfoValid({
        guestUserData,
        isLoggedIn,
        isShadowUser,
        paymentMethod,
        completePaymentMethod: completePaymentMethod as PaymentMethods,
        selectedGiftCard: selectedGiftCard as GiftCard,
        totalWithTips,
        overallPrice,
        items,
        giftCards: selectedInCartGiftCards,
      }): {
        return true;
      }
      case !!(paymentMethod === PaymentMethods.card && selectedPaymentCard): {
        return CartState.isDeliveryDataCorrectWithPaymentCard(
          items,
          deliveryData,
          selectedInCartGiftCards
        );
      }
      case !!(
        paymentMethod === PaymentMethods.gift_card &&
        selectedGiftCard &&
        selectedGiftCard?.currentBalance
      ): {
        return this.isDeliveryDataCorrectWithGiftCard({
          selectedGiftCard: selectedGiftCard as GiftCard,
          items,
          giftCards: selectedInCartGiftCards,
          totalWithTips,
          overallPrice,
          selectedPaymentCard: selectedPaymentCard as Card,
          completePaymentMethod: completePaymentMethod as PaymentMethods,
          isLoggedIn,
          guestUserData,
        });
      }
      case paymentMethod === PaymentMethods.apple_pay ||
        paymentMethod === PaymentMethods.google_pay: {
        return true;
      }
      default: {
        return false;
      }
    }
  }

  @Selector()
  static selectedPaymentCard({
    selectedPaymentCard,
  }: CartStateModel): Card | null {
    return selectedPaymentCard;
  }

  @Selector()
  static guestUserData({ guestUserData }: CartStateModel): GuestUserData {
    return guestUserData;
  }

  @Selector()
  static guestUserPhoneNumber({ guestUserData }: CartStateModel): string {
    return guestUserData.phone?.phoneNumber || '';
  }

  @Selector()
  static selectedGiftCard({
    selectedGiftCard,
  }: CartStateModel): GiftCard | null {
    return selectedGiftCard;
  }

  @Selector()
  static selectedGiftCardError({
    selectedGiftCardError,
  }: CartStateModel): boolean {
    return selectedGiftCardError;
  }

  @Selector()
  static completePaymentMethod({
    completePaymentMethod,
  }: CartStateModel): PaymentMethods | undefined {
    return completePaymentMethod;
  }

  @Selector()
  static paymentMethod({ paymentMethod }: CartStateModel): PaymentMethods {
    return paymentMethod;
  }

  @Selector()
  static isGiftCardCoverTotal({
    isGiftCardCoverTotal,
  }: CartStateModel): boolean {
    return isGiftCardCoverTotal;
  }

  @Selector()
  static quoteId({ quote }: CartStateModel): string {
    return quote?.id || '';
  }

  @Selector()
  static isEditing({ isEditing }: CartStateModel): boolean {
    return isEditing;
  }

  @Action(SetGiftCards)
  setGiftCards(
    { patchState }: StateContext<CartStateModel>,
    { giftCards }: SetGiftCards
  ) {
    patchState({
      giftCards,
    });
  }

  @Selector()
  static isSearchingDriver({ isSearchingDriver }: CartStateModel): boolean {
    return isSearchingDriver;
  }

  @Action(SetTranslations)
  setTranslations({ patchState }: StateContext<CartStateModel>) {
    patchState({
      translations: {
        deliveryFees: this.translateService.instant('PAY_NOW.DELIVERY_FEES'),
      },
    });
  }

  @Action(SetTableId)
  setTableId(
    { patchState }: StateContext<CartStateModel>,
    { tableId }: SetTableId
  ) {
    patchState({ tableId });
  }

  @Action(SetMenuId)
  setMenuId(
    { patchState }: StateContext<CartStateModel>,
    { menuId }: SetMenuId
  ) {
    patchState({ menuId });
  }

  @Action(SetTableName)
  setTableName(
    { patchState }: StateContext<CartStateModel>,
    { tableName }: SetTableName
  ) {
    patchState({ tableName });
  }

  @Action(SetTableSectionId)
  setTableSectionId(
    { patchState }: StateContext<CartStateModel>,
    { sectionId }: SetTableSectionId
  ) {
    patchState({ sectionId });
  }

  @Action(RemoveItemFromCart)
  removeItemFromCart(
    { getState, patchState }: StateContext<CartStateModel>,
    { item, itemType }: RemoveItemFromCart
  ) {
    if (itemType === OrderItemType.item) {
      const state: CartStateModel = copy(getState());

      const index = state.items.findIndex(currentItem =>
        this.isSameItems(currentItem, item as StateItem)
      );

      if (state.items[index].quantity === 1) {
        state.items.splice(index, 1);
      } else {
        state.items[index].quantity -= 1;
      }

      patchState({ ...state });

      this.store.dispatch(new CalculatePrices());
    } else if (itemType === OrderItemType.gift_card) {
      const state: CartStateModel = copy(getState());
      const updatedGiftCards = copy<any>(state.giftCards).filter(
        (card: GiftCardInForSelection) => {
          return !isEqual(
            omit(item, ['deliveryDate', 'quantity', 'item']),
            omit(card, ['deliveryDate', 'quantity', 'item'])
          );
        }
      );

      state.giftCards = updatedGiftCards;
      state.items = state.items.filter(item => !item.selectedInCart);

      patchState({ ...state });
    }

    if (!getState().items.length) {
      this.sessionStorage.removeItem(SessionStorageKeys.isReorderingDisabled);
    }
  }

  @Action(DeleteItem)
  deleteItem(
    { getState, patchState }: StateContext<CartStateModel>,
    { item, itemType }: DeleteItem
  ) {
    if (itemType === OrderItemType.item) {
      const items: StateItem[] = (copy(getState().items) as StateItem[]).filter(
        (stateItem: StateItem) => {
          return !this.isSameItems(stateItem, item as StateItem);
        }
      );

      patchState({ items });

      this.store.dispatch(new CalculatePrices());
    }
  }

  @Action(AddToCart)
  addToCart(
    { getState, patchState, dispatch }: StateContext<CartStateModel>,
    { item, category }: AddToCart
  ) {
    const { giftCards } = getState();
    const orderType = this.store.selectSnapshot(OrderDataState.orderType);
    const state = getState();
    const updatedState = this.updateStateWithAddedItem(item, state, category);

    patchState({ ...updatedState });

    const actions = [new CalculatePrices()];

    if (giftCards?.length) {
      actions.push(new ClearGiftCards());
    }

    dispatch(actions).subscribe(() =>
      dispatch(new CalculateCheck(!!giftCards.length, orderType, false))
    );
  }

  @Action(SetItems)
  setItems({ patchState }: StateContext<CartStateModel>, { items }: SetItems) {
    const giftCards: GiftCard[] = items.filter(
      (item): item is GiftCard => 'giftCardNumber' in item
    );

    const stateItems: StateItem[] = items.filter(
      (item): item is StateItem => !('giftCardNumber' in item)
    );

    patchState({
      items: stateItems,
      giftCards,
    });
  }

  @Action(ClearItems)
  clearItems({ patchState }: StateContext<CartStateModel>) {
    patchState({ items: [] });
  }

  @Action(AddGiftCard)
  addGiftCard(
    { getState, patchState }: StateContext<CartStateModel>,
    { giftCard }: AddGiftCard
  ): void {
    giftCard.selectedInCart = true;
    const existingGiftCards: GiftCard[] = copy(getState().giftCards);
    const giftCards: GiftCard[] = [
      ...existingGiftCards,
      { ...giftCard, selectedInCart: true, cartItemId: uuid() },
    ];

    patchState({ giftCards });
  }

  @Action(ClearGiftCards)
  clearGiftCards({ patchState }: StateContext<CartStateModel>): void {
    patchState({ giftCards: [] });
  }

  @Action(ResetCheckCalculations)
  resetCheckCalculations({ patchState }: StateContext<CartStateModel>) {
    patchState({ checkCalculations: DEFAULT_CHECK_CALCULATIONS });
  }

  @Action(SetCheckCalculations)
  setCheckCalculations(
    { patchState }: StateContext<CartStateModel>,
    { checkCalculations }: SetCheckCalculations
  ) {
    patchState({ checkCalculations });
  }

  @Action(CalculateCheck)
  calculateCheck(
    { getState, dispatch }: StateContext<CartStateModel>,
    { isGiftCard, orderType, openDialog }: CalculateCheck
  ) {
    if (!!isGiftCard) {
      return 0;
    }

    dispatch(new SetOverallPrice());

    const { calculatedItem, items, overallPrice } = getState();
    const { id } = this.store.selectSnapshot(VenueState.venue);
    const deliveryDate: moment.Moment = this.store.selectSnapshot(
      OrderDataState.deliveryDay
    );

    const totalExemptSubtotal = this.getSelectedCalculatedItem(
      calculatedItem,
      items,
      deliveryDate
    ).reduce((sum, currentItem, index) => {
      if (
        items[index].selectedInCart &&
        items[index].taxRule === TaxRules.taxFree
      ) {
        return sum + this.calculateDecimals(currentItem.price);
      } else {
        return (sum += 0);
      }
    }, 0);

    const checkoutDialogClosed =
      this.matDialog.getDialogById(MatDialogId.pay_now)?.getState() ===
      undefined;

    const isLoggedIn = this.store.selectSnapshot(SessionState.isLoggedIn);
    const isShadowUser = this.store.selectSnapshot(SessionState.isShadowUser);

    (isLoggedIn || isShadowUser) && dispatch(new InitializePaymentMethods());

    openDialog &&
      !!items.length &&
      overallPrice === 0 &&
      checkoutDialogClosed &&
      this.ngZone.run(() => {
        this.store.dispatch(new OpenPayNowDialog());
      });

    if (overallPrice > 0) {
      return this.catalogService
        .calculateCheck(overallPrice, totalExemptSubtotal, id, orderType)
        .pipe(
          tap({
            next: checkCalculations => {
              dispatch(new SetCheckCalculations(checkCalculations));
              setTimeout(() => dispatch(new SetTips(this.getTips())));

              openDialog &&
                this.ngZone.run(() => {
                  this.store.dispatch(new OpenPayNowDialog());
                });
            },
            /*
         TODO: Remove when API is adjusted. Only shadow users can call 'calculate' request,
         but they can't call 'address' req. And opposite
        */
            error: () =>
              checkoutDialogClosed &&
              this.ngZone.run(() => {
                this.store.dispatch(new OpenPayNowDialog());
              }),
          })
        );
    }

    dispatch([new ResetCheckCalculations(), new ResetTips()]);

    return 0;
  }

  @Action(SetTips)
  setTips(
    { patchState }: StateContext<CartStateModel>,
    { tips }: SetTips
  ): void {
    patchState({ tips });
  }

  @Action(ResetTips)
  resetTips({ patchState }: StateContext<CartStateModel>) {
    patchState({
      tips: {
        ...DEFAULT_TIPS,
        percentage: this.store.selectSnapshot(VenueState.venueTips)[0],
      },
    });
  }

  @Action(ClearCart)
  clearCart({ getState, patchState }: StateContext<CartStateModel>) {
    const { items, tableName } = getState();

    const orderedItems: StateItem[] = this.getFilteredAvailableItems(
      items.filter(item => item.selectedInCart)
    );
    const previouslyOrderedItems: StateItem[] = orderedItems?.length
      ? copy(orderedItems)
      : copy(getState()?.previouslyOrderedItems) || [];

    const itemsLeft = [
      ...items.filter(item => !item.selectedInCart),
      ...this.getFilteredUnavailableItems(
        items.filter(item => item.selectedInCart)
      ),
    ];

    patchState({
      ...DEFAULT_CART,
      tableName,
      items: itemsLeft,
      previouslyOrderedItems,
    });

    this.store.dispatch(new ClearOrderDataPartAfterLogout());
    itemsLeft.length && this.store.dispatch(new CalculatePrices());
  }

  @Action(CalculatePrices)
  calculatePrices({ patchState, getState }: StateContext<CartStateModel>) {
    const { id: venueId } = this.store.selectSnapshot(VenueState.venue);
    const stateItems: StateItem[] = copy(getState().items);
    const itemsForRequest: ItemsForCalculation[] = stateItems.map(
      ({
        item: { itemId },
        size: { id: sizeId },
        menuId,
        menuSectionId,
        modifiers,
        bundleItems,
      }: StateItem) => {
        const modifiersToRequest: ModifierCalculatePriceRequest[] =
          modifiers.map(
            ({ modifierId, selectedOptionId }: SelectedModifier) => ({
              modifierId,
              modifierOptionIds: [selectedOptionId],
            })
          );

        return {
          itemId,
          menuId,
          menuSectionId,
          sizeId,
          modifiers: modifiersToRequest,
          bundleItems,
        };
      }
    );

    if (itemsForRequest?.length) {
      const requestData: CalculateItemsPriceRequest = {
        venueId,
        items: itemsForRequest,
      };

      return this.catalogService.calculatePrices(requestData).pipe(
        tap({
          next: (calculatedItems: CalculatedItem[]) => {
            patchState({
              calculatedItem: calculatedItems,
              notAvailableItemName: '',
              calculationError: false,
            });
          },
          error: error => {
            const wrongModifierId = error.message.split('"')[1];
            let notAvailableItemName = '';

            stateItems
              .filter(item => item.selectedInCart)
              .forEach(item => {
                item.modifiers.forEach(modifier => {
                  if (modifier.selectedOptionId === wrongModifierId) {
                    notAvailableItemName = item.item.name;
                  }
                });
              });

            if (notAvailableItemName) {
              patchState({
                calculatedItem: [],
                notAvailableItemName,
                calculationError: false,
              });
            } else {
              patchState({
                calculatedItem: [],
                calculationError: true,
              });
            }
          },
          finalize: () => this.store.dispatch(new SetOverallPrice()),
        })
      );
    } else {
      patchState({ calculatedItem: [] });

      return of();
    }
  }

  @Action(CalculateCheckAndCreateDeliveryQuote)
  setDeliveryData(
    { patchState, dispatch, getState }: StateContext<CartStateModel>,
    { ignoreLoader, searchingForDriver }: CalculateCheckAndCreateDeliveryQuote
  ) {
    const isLoggedIn = this.store.selectSnapshot(SessionState.isLoggedIn);
    const orderType = this.store.selectSnapshot(OrderDataState.orderType);
    const deliveryData = this.store.selectSnapshot(OrderDataState.orderData);
    const isGiftCard = !!getState().giftCards.length;

    this.actions$
      .pipe(ofActionCompleted(CalculateCheck), first())
      .subscribe(() => {
        if (
          deliveryData?.orderType === DeliveryMethod.Delivery &&
          !isGiftCard
        ) {
          const userProfileAddress = this.store.selectSnapshot(
            ProfileState.addresses
          );

          !deliveryData.data.time && (deliveryData.data.asap = true);
          (!!deliveryData.data.address ||
            (isLoggedIn && !!userProfileAddress.length)) &&
            dispatch(
              new CreateDeliveryQuote(ignoreLoader, {
                searchingForDriver,
              })
            );
        } else {
          this.removeDeliveryQuoteFromState(
            getState().checkCalculations,
            patchState,
            getState
          );
        }
      });

    dispatch([
      new SetOverallPrice(),
      new CalculateCheck(!!getState().giftCards.length, orderType, false),
    ]);
  }

  @Action(SetOverallPrice)
  setOverallPrice({ patchState, getState }: StateContext<CartStateModel>) {
    const stateItems: StateItem[] = copy(getState().items);
    const { calculatedItem } = getState();
    const deliveryDate: moment.Moment = this.store.selectSnapshot(
      OrderDataState.deliveryDay
    );

    const availableItems: StateItem[] = stateItems.filter(item =>
      this.isItemAvailable(item, deliveryDate)
    );

    const itemsWithCalculatedPrice = this.itemsWithCalculatedPrice(
      stateItems,
      calculatedItem
    );

    const overallPrice: number = this.getFilteredAvailableItems(
      availableItems
    ).reduce(
      (acc: number, { price = 0, quantity = 0 }: StateItem) =>
        acc + price * quantity,
      0
    );

    patchState({ overallPrice, items: itemsWithCalculatedPrice });
  }

  @Action(SaveGuestPaymentCard)
  saveGuestPaymentCard(
    { patchState, getState }: StateContext<CartStateModel>,
    { card }: SaveGuestPaymentCard
  ) {
    const guestUserDataPrevious: GuestUserData = copy(getState().guestUserData);

    patchState({
      guestUserData: { ...guestUserDataPrevious, card, isCardValid: true },
    });
  }

  @Action(SavePaymentCard)
  SavePaymentCard(
    { dispatch }: StateContext<CartStateModel>,
    { card }: SavePaymentCard
  ) {
    dispatch(new ShowSpinner());

    return this.paymentService.createCard(card).pipe(
      tap(data => {
        const isLoggedIn = this.store.selectSnapshot(SessionState.isLoggedIn);

        if (isLoggedIn) {
          this.store.dispatch([
            new HideSpinner(),
            new InitializePaymentMethods(),
          ]);
        } else {
          this.store.dispatch([
            new HideSpinner(),
            new SelectCardForOrder({
              id: data.id,
              cardExpirationDate: card.cardExpirationDate,
              cardNumber: card.cardNumber,
              cardholderName: card.cardholderName,
              default: false,
              cvv: '',
              redactedCardNumber: '',
              cardBrand: data.cardBrand,
            }),
          ]);
        }
      })
    );
  }

  @Action(SelectCardForOrder)
  selectCardForOrder(
    { patchState }: StateContext<CartStateModel>,
    { card }: SelectCardForOrder
  ) {
    patchState({
      selectedPaymentCard: card,
    });
  }

  @Action(SelectGiftCardForOrder)
  selectGiftCardForOrder(
    { patchState }: StateContext<CartStateModel>,
    { giftcard }: SelectGiftCardForOrder
  ) {
    patchState({
      selectedGiftCard: giftcard,
    });
  }

  @Action(GiftCardDetails)
  GiftCardDetails(
    { patchState, dispatch }: StateContext<CartStateModel>,
    { scannedGiftcardId }: GiftCardDetails
  ) {
    dispatch(new ShowSpinner());

    return this.paymentService.getGiftCardDetails(scannedGiftcardId).pipe(
      tap({
        next: (giftCard: GiftCard) => {
          if (
            giftCard.venueId !== this.store.selectSnapshot(VenueState.venueId)
          ) {
            patchState({
              selectedGiftCardError: true,
            });
          } else {
            patchState({
              selectedGiftCard: giftCard,
            });
          }
        },
        error: () => {
          patchState({
            selectedGiftCardError: true,
          });
        },
        finalize: () => {
          dispatch(new HideSpinner());
        },
      })
    );
  }

  @Action(ClearSelectedGiftCardError)
  clearSelectedGiftCardError({ patchState }: StateContext<CartStateModel>) {
    patchState({
      selectedGiftCardError: false,
    });
  }

  @Action(SetIsGiftCardCoverTotal)
  setIsGiftCardCoverTotal(
    { patchState }: StateContext<CartStateModel>,
    { isGiftCardCoverTotal }: SetIsGiftCardCoverTotal
  ) {
    patchState({
      isGiftCardCoverTotal,
    });
  }

  @Action(MergeGiftCard)
  MergeGiftCard(
    { dispatch }: StateContext<CartStateModel>,
    { giftCardFrom, giftCardTo }: MergeGiftCard
  ) {
    dispatch(new ShowSpinner());

    return this.paymentService
      .mergeGiftCards({ giftCardFrom, giftCardTo })
      .pipe(
        tap({
          finalize: () => {
            dispatch(new HideSpinner());
          },
        })
      );
  }

  @Action(CreateDeliveryQuote)
  createDeliveryQuote(
    { dispatch, patchState, getState }: StateContext<CartStateModel>,
    { ignoreLoader, quoteSearchingDriver }: CreateDeliveryQuote
  ) {
    !ignoreLoader && dispatch(new ShowSpinner());

    const { checkCalculations } = getState();
    const profileInfo = this.store.selectSnapshot(
      ProfileState.profileInfo
    ) as Profile;
    const deliveryMethodData = this.store.selectSnapshot(
      OrderDataState.deliveryMethodData
    );
    const { address: deliveryAddress, asap, time, day } = deliveryMethodData;
    const address =
      deliveryAddress ||
      ({
        ...this.store
          .selectSnapshot(ProfileState.addresses)
          .find((address: UserProfileAddress) => address.isDefault),
      } as AddressForm);

    if (!address) {
      dispatch(new HideSpinner());

      return;
    }

    const deliveryQuoteBody: CreateQuoteRequestBody = {
      dropoffAddress: {
        ...address,
        name: this.store.selectSnapshot(SessionState.isLoggedIn)
          ? `${profileInfo?.firstName} ${profileInfo?.lastName}`
          : 'User',
      },
      orderAmount: getAmountObject(checkCalculations.grossTotal),
      venueId: this.store.selectSnapshot(VenueState.venueId),
    };

    if (asap) {
      deliveryQuoteBody.asap = true;
    } else if (day) {
      const [hours, minutes] = getHoursAndMinutesFromInterval(time, 0);
      deliveryQuoteBody.scheduledFor = moment(day.day)
        .set({ hours, minutes })
        .format();
    }

    return this.deliveryService.createDeliveryQuote(deliveryQuoteBody).pipe(
      tap(quoteData => {
        patchState({
          quote: quoteData,
          isSearchingDriver: false,
          isAddressCoveredByDelivery: true,
        });
        this.store.dispatch(new SetTranslations());
        const overallPrice: number = getState().overallPrice;
        const deliveryFeesTranslation = getState().translations.deliveryFees;
        const deliveryFeeEntry: CheckTax = {
          name: deliveryFeesTranslation,
          description: '',
          grossPrice: quoteData.customerFee as number,
          customerFee: quoteData.customerFee as number,
          netPrice: 0,
          percentage: 0,
        };
        const deliveryFeesEntryIndex = this.getDeliveryFeesEntryIndex(
          checkCalculations,
          getState
        );

        if (deliveryFeesEntryIndex > -1) {
          const entries: CheckTax[] = cloneDeep(checkCalculations.entries);
          const netTotal =
            quoteData.customerFee === entries[deliveryFeesEntryIndex].grossPrice
              ? checkCalculations.netTotal
              : checkCalculations.netTotal -
                entries[deliveryFeesEntryIndex].grossPrice +
                (quoteData.customerFee as number);
          deliveryFeeEntry.percentage =
            (quoteData.customerFee as number) / netTotal;
          entries.splice(deliveryFeesEntryIndex, 1, deliveryFeeEntry);
          dispatch(
            overallPrice
              ? new SetCheckCalculations({
                  ...checkCalculations,
                  entries,
                  netTotal,
                })
              : new ResetCheckCalculations()
          );
          return;
        }
        const netTotal =
          checkCalculations.netTotal + (quoteData.customerFee as number);
        deliveryFeeEntry.percentage =
          (quoteData.customerFee as number) / netTotal;
        dispatch(
          overallPrice
            ? new SetCheckCalculations({
                ...checkCalculations,
                netTotal,
                entries: [...checkCalculations.entries, deliveryFeeEntry],
              })
            : new ResetCheckCalculations()
        );
      }),
      catchError(error => {
        if (
          error?.code === DeliveryMethodQuoteCreationErrorCode.AddressNotCovered
        ) {
          patchState({
            isAddressCoveredByDelivery: false,
          });
        }
        if (CartState.isSearchingForDriver(quoteSearchingDriver, error?.code)) {
          patchState({
            isSearchingDriver:
              !quoteSearchingDriver?.isLastSearchingDriverApiCall,
            quote: null,
          });

          if (quoteSearchingDriver?.isLastSearchingDriverApiCall) {
            this.notificationService.showError(
              this.translateService.instant('PAY_NOW.NO_DRIVER_AVAILABLE')
            );
          }
        } else {
          this.removeDeliveryQuoteFromState(
            checkCalculations,
            patchState,
            getState
          );
          this.showDeliveryQuoteErrorMessage(error);
        }
        return of();
      }),
      finalize(() => {
        !ignoreLoader && dispatch(new HideSpinner());
        !getState().overallPrice && dispatch(new ResetCheckCalculations());
      })
    );
  }

  @Action(SetIsSearchingDriver)
  setIsSearchingDriver(
    { patchState }: StateContext<CartStateModel>,
    { isSearchingDriver, isNoDriverAvailable }: SetIsSearchingDriver
  ) {
    patchState({ isSearchingDriver });

    if (isNoDriverAvailable) {
      this.notificationService.showError(
        this.translateService.instant('PAY_NOW.NO_DRIVER_AVAILABLE')
      );
    }
  }

  @Action(SetGuestUserData)
  setGuestUserData(
    { patchState, getState }: StateContext<CartStateModel>,
    { guestUserFormData, isFormDataValid }: SetGuestUserData
  ) {
    const { name, email, phone } = guestUserFormData;
    const card: NewCardToAdd = copy(getState().guestUserData.card);
    const guestUserDataExist: GuestUserData = copy(getState().guestUserData);

    patchState({
      guestUserData: {
        ...guestUserDataExist,
        card,
        isFormDataValid,
        name,
        shadowUserUsername: {
          email,
          phone,
        },
      },
    });
  }

  @Action(ClearGuestUserData)
  clearGuestUserData({ patchState }: StateContext<CartStateModel>) {
    patchState({ guestUserData: copy(DEFAULT_CART.guestUserData) });
  }

  @Action(SetGuestUserPhoneData)
  setGuestUserPhoneData(
    { patchState, getState }: StateContext<CartStateModel>,
    { phone }: SetGuestUserPhoneData
  ) {
    const { guestUserData } = getState();

    patchState({
      guestUserData: {
        ...guestUserData,
        phone,
      },
    });
  }

  @Action(SetPaymentMethod)
  setPaymentMethod(
    { patchState }: StateContext<CartStateModel>,
    { paymentMethod }: SetPaymentMethod
  ) {
    patchState({ paymentMethod });
  }

  @Action(SetCompletePaymentMethod)
  setCompletePaymentMethod(
    { patchState }: StateContext<CartStateModel>,
    { completePaymentMethod }: SetCompletePaymentMethod
  ) {
    patchState({ completePaymentMethod });
  }

  @Action(SetCartEditing)
  setCartEditing(
    { patchState }: StateContext<CartStateModel>,
    { value }: SetCartEditing
  ) {
    patchState({ isEditing: value });
  }

  private calculateDecimals(price: Money): number {
    return Number(
      (Number(price.value) / 10 ** price.currency!.decimal).toFixed(2)
    );
  }

  @Action(InitializeCheckout)
  initializeCheckout({ getState, dispatch }: StateContext<CartStateModel>) {
    const isGifCard = !!getState().giftCards?.length;

    dispatch(new OpenPayNowDialog(isGifCard));
  }

  private isSameItems(firstItem: StateItem, secondItem: StateItem): boolean {
    return (
      firstItem.cartItemId === secondItem.cartItemId &&
      firstItem.item.itemId === secondItem.item.itemId &&
      isEqual(firstItem.modifiers, secondItem.modifiers) &&
      isEqual(firstItem.size, secondItem.size) &&
      isEqual(firstItem.bundleItems, secondItem.bundleItems) &&
      firstItem.specialRequest === secondItem.specialRequest
    );
  }

  @Action(ClearSenderDataOfGiftCards)
  clearSenderDataOfGiftCards({
    getState,
    patchState,
  }: StateContext<CartStateModel>) {
    const giftCards = cloneDeep(getState().giftCards);

    patchState({
      giftCards: giftCards.map(giftCard => {
        giftCard.senderName = '';
        giftCard.senderEmail = '';
        giftCard.senderProfileId = '';

        return giftCard;
      }),
    });
  }

  @Action(AssignSenderToGiftCard)
  assignSenderToGiftCard(
    { getState, patchState }: StateContext<CartStateModel>,
    { senderInfo }: AssignSenderToGiftCard
  ) {
    const { senderName, senderEmail, senderProfileId } = senderInfo;
    const giftCards = cloneDeep(getState().giftCards);

    patchState({
      giftCards: giftCards.map(giftCard => {
        giftCard.senderName = senderName;
        giftCard.senderEmail = senderEmail;
        giftCard.senderProfileId = senderProfileId;

        return giftCard;
      }),
    });
  }

  private getDeliveryFeesEntryIndex(
    checkCalculations: CheckCalculation,
    getState: () => CartStateModel
  ): number {
    const deliveryFeesTranslation = getState().translations.deliveryFees;

    return checkCalculations.entries.findIndex(
      entry => entry.name === deliveryFeesTranslation
    );
  }

  private showDeliveryQuoteErrorMessage(error: any): void {
    switch (true) {
      case error?.code ===
        DeliveryMethodQuoteCreationErrorCode.AddressNotCovered: {
        this.notificationService.showError(
          this.translateService.instant('CART.address_not_covered')
        );

        break;
      }
      case error?.code ===
        DeliveryMethodQuoteCreationErrorCode.ErrOrderValueTooLow: {
        this.notificationService.showError(
          this.translateService.instant('CART.order_value_too_low', {
            errorMessage: error.message,
          })
        );

        break;
      }
      default: {
        this.notificationService.showError(
          this.translateService.instant('CART.delivery_not_available')
        );
      }
    }
  }

  private static isSearchingForDriver(
    quoteSearchingDriver: QuoteSearchingDriver | undefined,
    errorCode: number
  ): boolean {
    return !!(
      quoteSearchingDriver?.searchingForDriver &&
      errorCode === DeliveryMethodQuoteCreationErrorCode.NoDriversAvailable
    );
  }

  private static isDeliveryDataCorrectWithPaymentCard(
    items: StateItem[],
    deliveryData: DeliveryData | null,
    giftCards: GiftCard[]
  ): boolean {
    return !!items.length ? !!deliveryData : !!giftCards.length ? true : false;
  }

  private static isDeliveryDataCorrectWithGiftCard({
    selectedGiftCard,
    items,
    giftCards,
    totalWithTips,
    overallPrice,
    selectedPaymentCard,
    completePaymentMethod,
    isLoggedIn,
    guestUserData,
  }: DeliveryWithGiftCardData): boolean {
    if (!!items.length) {
      return totalWithTips <= (selectedGiftCard?.currentBalance as number)
        ? true
        : CartState.isCompletePaymentDataWithGiftCardCorrect(
            selectedPaymentCard,
            completePaymentMethod,
            isLoggedIn,
            guestUserData
          );
    }

    if (!!giftCards.length) {
      return overallPrice <= (selectedGiftCard?.currentBalance as number)
        ? true
        : CartState.isCompletePaymentDataWithGiftCardCorrect(
            selectedPaymentCard,
            completePaymentMethod
          );
    }

    return false;
  }

  private static isCompletePaymentDataWithGiftCardCorrect(
    selectedPaymentCard: Card,
    completePaymentMethod?: PaymentMethods,
    isLoggedIn?: boolean,
    guestUserData?: GuestUserData
  ): boolean {
    return (
      (completePaymentMethod === PaymentMethods.card &&
        !!selectedPaymentCard &&
        isLoggedIn) ||
      (completePaymentMethod === PaymentMethods.card &&
        !!guestUserData?.isCardValid &&
        !isLoggedIn) ||
      completePaymentMethod !== PaymentMethods.card
    );
  }

  private static isCurbsideDeliveryTypeDataIncorrect(
    deliveryData: DeliveryData | null
  ): boolean {
    return (
      deliveryData?.orderType === DeliveryMethod.Curbside &&
      !isCarValid(deliveryData?.data?.carInfo)
    );
  }

  private static isGuestUserInfoValid({
    guestUserData,
    isLoggedIn,
    isShadowUser,
    paymentMethod,
    completePaymentMethod,
    selectedGiftCard,
    totalWithTips,
    overallPrice,
    items,
    giftCards,
    orderType,
  }: GuestUserValidInfoData): boolean {
    const { isCardValid, isFormDataValid, phone, card } = guestUserData;

    if (!isLoggedIn) {
      if (orderType === DeliveryMethod.Delivery && !phone?.phoneNumber) {
        return false;
      }

      switch (true) {
        case paymentMethod === PaymentMethods.card: {
          return isShadowUser ? isCardValid : isCardValid && isFormDataValid;
        }
        case paymentMethod === PaymentMethods.gift_card &&
          !!selectedGiftCard &&
          !!selectedGiftCard?.currentBalance: {
          return this.isDeliveryDataCorrectWithGiftCard({
            selectedPaymentCard: card as any,
            selectedGiftCard,
            items,
            giftCards,
            totalWithTips,
            overallPrice,
            completePaymentMethod,
            guestUserData,
          });
        }
        case paymentMethod === PaymentMethods.apple_pay ||
          paymentMethod === PaymentMethods.google_pay: {
          return isFormDataValid;
        }
        default: {
          return false;
        }
      }
    }

    return false;
  }

  private static isPayButtonEnabledWhenBuyingGiftCards(
    giftCards: GiftCard[],
    selectedGiftCard: GiftCard | null,
    paymentMethods: PaymentMethods
  ): boolean {
    if (paymentMethods === PaymentMethods.gift_card) {
      const sumForGiftCards = giftCards.reduce(
        (acc: number, { amount }: GiftCard) => acc + (amount || 0),
        0
      );

      return (selectedGiftCard?.currentBalance ?? 0) >= sumForGiftCards;
    }

    return true;
  }

  private removeDeliveryQuoteFromState(
    checkCalculations: CheckCalculation,
    patchState: (val: Partial<CartStateModel>) => CartStateModel,
    getState: () => CartStateModel
  ): void {
    const deliveryFeesEntryIndex = this.getDeliveryFeesEntryIndex(
      checkCalculations,
      getState
    );

    if (deliveryFeesEntryIndex > -1) {
      const deliveryFeesTranslation = getState().translations.deliveryFees;

      patchState({
        quote: null,
        checkCalculations: {
          ...checkCalculations,
          netTotal:
            checkCalculations.netTotal -
            checkCalculations.entries[deliveryFeesEntryIndex].grossPrice,
          entries: checkCalculations.entries.filter(
            entry => entry.name !== deliveryFeesTranslation
          ),
        },
      });
    }
  }

  private getSelectedCalculatedItem(
    calculatedItem: CalculatedItem[],
    stateItems: StateItem[],
    deliveryDate: moment.Moment
  ): CalculatedItem[] {
    return stateItems
      .map((item, index) => {
        if (this.isItemAvailable(item, deliveryDate)) {
          return calculatedItem[index];
        } else {
          return null;
        }
      })
      .filter(item => item !== null) as CalculatedItem[];
  }

  private itemsWithCalculatedPrice(
    items: StateItem[],
    calculatedItem: CalculatedItem[]
  ): StateItem[] {
    return items.map((item: StateItem, idx: number) => ({
      ...item,
      price: calculatedItem[idx]?.totalPrice?.value || 0,
    }));
  }

  private isItemAvailable(
    item: StateItem,
    deliveryDate: moment.Moment
  ): boolean {
    return (
      (item !== null &&
        item.selectedInCart &&
        (item.item.availability.isAvailable ||
          deliveryDate.isAfter(
            moment(item.item.availability.nextTimeAvailable)
          ))) ||
      false
    );
  }

  public getFilteredAvailableItems(items: StateItem[]): StateItem[] {
    const menus: Menu[] = this.store.selectSnapshot(VenueState.menus);
    const orderType: DeliveryType = this.store.selectSnapshot(
      OrderDataState.orderType
    );
    const selectedOrderPeriod: DeliveryDataDateTime = this.store.selectSnapshot(
      OrderDataState.selectedOrderPeriod
    );

    return getFilteredCartItems(
      copy(items),
      orderType,
      selectedOrderPeriod,
      menus,
      true
    );
  }

  private getFilteredUnavailableItems(items: StateItem[]): StateItem[] {
    let filteredItems: StateItem[] = copy(items);
    const menus: Menu[] = this.store.selectSnapshot(VenueState.menus);
    const orderType: DeliveryType = this.store.selectSnapshot(
      OrderDataState.orderType
    );
    const selectedOrderPeriod: DeliveryDataDateTime = this.store.selectSnapshot(
      OrderDataState.selectedOrderPeriod
    );

    if (items.length && (items[0] as any).currentBalance) {
      return items;
    } else {
      return filteredItems.filter(
        (item: StateItem) =>
          !isAvailableMenuForCurrentOrderType(item.menuId, orderType, menus) ||
          !isAvailableMenuItemForSelectedTime(
            item.menuId,
            orderType,
            selectedOrderPeriod,
            menus,
            true
          )
      );
    }
  }

  private getTips(): Tips {
    const { percentage, isCustom }: Tips = this.store.selectSnapshot(
      CartState.tips
    );
    const { netTotal } = this.store.selectSnapshot(CartState.checkCalculations);
    const tips: Tips = {
      isCustom,
      percentage,
      absolute: Math.round((netTotal * (percentage || 0)) / 100),
    };

    return tips;
  }

  private updateStateWithAddedItem(
    item: StateItem,
    state: CartStateModel,
    category?: ItemCategory
  ): CartStateModel {
    const updatedState = {
      ...state,
      items: [...state.items],
    };
    const ignoredComparisonFields: string[] = [
      'cartItemId',
      'price',
      'quantity',
      'selectedInCart',
      'isCurrentMenuAvailable',
      'disableOrdering',
      'category',
      'bundleItems',
    ];
    const { items, isEditing, venueId } = state;
    const itemIndex = items.findIndex(
      currentItem => currentItem.cartItemId === item.cartItemId
    );
    const existingItemIndex = items.findIndex(currentItem =>
      isEqual(
        omit(currentItem, ignoredComparisonFields),
        omit(item, ignoredComparisonFields)
      )
    );

    switch (true) {
      case existingItemIndex >= 0 && existingItemIndex !== itemIndex:
        items[existingItemIndex].quantity += item.quantity;
        updatedState.items.splice(existingItemIndex, 1, {
          ...items[existingItemIndex],
        });

        if (itemIndex > -1) {
          updatedState.items.splice(itemIndex, 1);
        }

        updatedState.isEditing = false;

        break;
      case itemIndex >= 0 && isEditing:
        updatedState.items.splice(itemIndex, 1, { ...item });
        updatedState.isEditing = false;

        break;
      case itemIndex >= 0 && !isEditing:
        item.quantity += 1;
        updatedState.items.splice(itemIndex, 1, { ...item });

        break;
      default: {
        updatedState.items.push({
          ...item,
          category,
          selectedInCart: true,
        });

        break;
      }
    }

    if (!venueId) {
      updatedState.venueId = this.store.selectSnapshot(VenueState.venueId);
    }

    item?.bundleItems?.map(item => cleanObject(item));

    return updatedState;
  }
}

<ng-container *ngIf="(giftCards$ | async) || []; let giftCards">
  <ng-container *ngIf="items$ | async; let items">
    <rs-cart-dialog-main-view
      [checkoutButtonDisabled]="
        !((giftCards | isSelectedItem) || (items | isSelectedItem))
      "
      [isLoggedIn]="isLoggedIn$ | async"
      [isShadowUser]="isShadowUser$ | async"
      [isGiftCardInCart]="isGiftCardInCart"
    >
      <rs-gift-cards-list-in-order
        *ngIf="isGiftCardInCart; else rsFoodItemsInCart"
        [items]="giftCards"
        [showCheckboxes]="true"
        (changedItemSelection)="selectItem($event)"
        (changedItemsSelection)="selectAllItems($event)"
        (decreaseItemQuantity)="decreaseItemQuantityInCart($event)"
        (toOrdering)="goToOrdering()"
      >
      </rs-gift-cards-list-in-order>

      <ng-template #rsFoodItemsInCart>
        <rs-list-of-items-in-order
          *ngIf="items.length"
          [items]="
            items | rsAddItemsAvailability: orderType:selectedOrderPeriod:menus
          "
          [amount]="(badgeNumber$ | async) || '0'"
          [showCheckboxes]="true"
          (changedItemSelection)="selectItem($event)"
          (changedItemsSelection)="selectAllItems($event)"
          (toOrdering)="goToOrdering()"
          (clickByItem)="handleClickByItem($event)"
          (increaseItemQuantity)="increaseItemQuantityInCart($event)"
          (decreaseItemQuantity)="decreaseItemQuantityInCart($event)"
        >
        </rs-list-of-items-in-order>
      </ng-template>

      <ng-template
        [ngIf]="!isProduction && !isGiftCardInCart && (isLoggedIn$ | async)"
      >
        <ng-container *ngIf="suggestions$ | async; let suggestions">
          <div
            *ngIf="suggestions?.length"
            class="mt-5 pt-3 rs-cart-dialog__suggestions"
          >
            <rs-suggestions-items-list
              [suggestedItems]="suggestions"
              [title]="'CART.Goes_Well_With' | translate"
            >
              <!-- TODO create a bug because the part of flow is missed now after MFT-3370
              (addItem)="increaseSuggestionItemQuantityInCart($event)"
             -->
            </rs-suggestions-items-list>
          </div>
        </ng-container>
      </ng-template>
    </rs-cart-dialog-main-view>
  </ng-container>
</ng-container>

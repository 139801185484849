import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';

import { Store } from '@ngxs/store';
import moment from 'moment/moment';
import { combineLatest, map, Observable } from 'rxjs';

import { CartEntity, CartState } from 'src/app/_shared/_ngxs/cart.state';
import { OrderDataState } from 'src/app/_shared/_ngxs/order-data.state';
import { OrderState } from 'src/app/_shared/_ngxs/order.state';
import { SessionState } from 'src/app/_shared/_ngxs/authentication.state';
import { VenueState } from '../../../../../_shared/_ngxs/venue.state';
import { CalculateCheckAndCreateDeliveryQuote } from 'src/app/_shared/_ngxs/cart.actions';

import { PaymentMethods } from 'src/app/_shared/_enums/payment-methods.enum';
import { PaymentIcons } from 'src/app/_shared/_enums/digital-storefront-icons.enum';
import { PAYMENT_ICONS_DIRECTORY } from 'src/app/_shared/_constants/digital-storefront.constants';

import {
  DeliveryMethod,
  DeliveryType,
  OrderType,
} from 'src/app/_shared/_enums/order.enum';
import { untilDestroyed } from 'src/app/_shared/_utils/until-destroyed';
import { SvgIconService } from 'src/app/_services/svg-icon.service';

@Component({
  selector: 'rs-pay-now-mobile-view',
  templateUrl: './pay-now-mobile-view.component.html',
  styleUrls: [
    './pay-now-mobile-view.component.scss',
    '../../../../../_shared/_styles/marketing/checkout.scss',
    '../apple-google-pay.scss',
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PayNowMobileViewComponent {
  @Input() isGiftCard: boolean = false;
  @Input() isSearchingDriver: boolean = false;
  @Input() showDelivery: boolean = false;

  public readonly isAddressCoveredByDelivery$ = this.store.select(
    CartState.isAddressCoveredByDelivery
  );
  public readonly checkCalculations$ = this.store.select(
    CartState.checkCalculations
  );
  public readonly isOpenTab$ = this.store.select(OrderState.isOpenTab);
  public readonly isOrderReadyForPayment$ = this.store.select(
    CartState.isOrderReadyForPayment
  );
  public readonly items$: Observable<CartEntity[]> = this.store
    .select(CartState.cartFilling)
    .pipe(map(data => data.items));
  public readonly price$: Observable<number> = this.store.select(
    CartState.overallPrice
  );
  public readonly badgeNumber$: Observable<string> = this.store.select(
    CartState.badgeNumber
  );
  public readonly selectedPaymentCard$ = this.store.select(
    CartState.selectedPaymentCard
  );
  public readonly guestUserData$ = this.store.select(CartState.guestUserData);
  public readonly isLoggedIn$ = this.store.select(SessionState.isLoggedIn);
  public readonly isShadowUser$ = this.store.select(SessionState.isShadowUser);
  public readonly tips$ = this.store.select(CartState.tips);
  public readonly venue$ = this.store.select(VenueState.venue);
  public readonly orderType$ = this.store.select(OrderDataState.orderType);
  public readonly orderPhone$ = this.store.select(OrderDataState.orderPhone);
  public readonly paymentMethod$ = this.store.select(CartState.paymentMethod);
  public readonly OrderType = OrderType;
  public readonly deliveryMethod: DeliveryMethod = DeliveryMethod.Delivery;
  public readonly paymentMethods = PaymentMethods;

  public orderType!: DeliveryType;
  public venueName: string = '';
  public currentPaymentMethod: PaymentMethods | undefined;
  public phoneIsEntered!: boolean;
  public isCardExpired: boolean = false;

  public readonly appleIcon = PaymentIcons.ApplePay;
  public readonly googleIcon = PaymentIcons.GooglePay;
  private readonly destroyed$ = untilDestroyed();

  @Output() payClicked = new EventEmitter<void>();

  constructor(
    private readonly store: Store,
    private readonly changeDetectorRef: ChangeDetectorRef,
    private readonly svgIconService: SvgIconService
  ) {
    this.venueName = this.store.selectSnapshot(VenueState.venue)?.name;
  }

  ngOnInit(): void {
    this.registerIcons();
    this.addSubscriptions();
  }

  private addSubscriptions(): void {
    this.orderType$
      .pipe(this.destroyed$())
      .subscribe((orderType: DeliveryType) => {
        orderType !== this.orderType &&
          !this.isGiftCard &&
          this.store.dispatch(
            new CalculateCheckAndCreateDeliveryQuote(true, false)
          );

        this.orderType = orderType;
      });

    combineLatest(this.orderType$, this.orderPhone$)
      .pipe(this.destroyed$())
      .subscribe(
        ([orderType, orderPhone]) =>
          (this.phoneIsEntered =
            orderType === DeliveryMethod.Delivery && !!orderPhone?.phoneNumber)
      );

    this.selectedPaymentCard$.subscribe(card => {
      this.cardExpired(card?.cardExpirationDate);
    });
  }

  public reactOnPaymentMethodChanged(paymentMethod: PaymentMethods): void {
    this.currentPaymentMethod = paymentMethod;
    // this.paymentMethodChanged.emit(this.currentPaymentMethod);
  }

  public cardExpired(date?: string) {
    const today = moment().format('YYYY-MM-DD');
    const expiry = moment(date).format('YYYY-MM-DD');
    if (date) {
      setTimeout(() => {
        this.isCardExpired = moment(expiry).isBefore(today, 'day');
      });
    }
    this.changeDetectorRef.detectChanges();
  }

  private registerIcons(): void {
    this.svgIconService.registerSvgIcons(
      [this.appleIcon, this.googleIcon],
      PAYMENT_ICONS_DIRECTORY
    );
  }
}

import { Injectable } from '@angular/core';

import {
  Action,
  Selector,
  State,
  StateContext,
  createSelector,
} from '@ngxs/store';
import { uniq } from 'lodash';

import { UpdateVenueOrderSettings } from './venue-order-settings.actions';
import { VenueState } from './venue.state';

import { DeliveryMethod, DeliveryType, OrderType } from '../_enums/order.enum';

import {
  ActiveDeliverySettings,
  DeliverySettings,
  DineInSettings,
  GeneralSettings,
  OrderSettings,
  PacingSettings,
  VenueOrderSettings,
} from '../_models/venue-order-settings';

import {
  ScheduleRange,
  ScheduleSettings,
} from 'src/app/_shared/_interfaces/date-settings';

import {
  Modify,
  OperatingHours,
  ServiceOpeningHours,
} from '../_models/common.interface';

import {
  getScheduleRangeBasedOnPacingSchedule,
  getScheduleRangeBasedOnVenueOperatingHours,
  secondsToMins,
} from '../_utils/dates';
import {
  PaymentMethods,
  PaymentMethodsBE,
} from '../_enums/payment-methods.enum';

// Note: discussed with Rafa Sabo 5/28/2024
// averagePreparationTime can't be less 30 mins
// intervalInMinutes can't be less 30 mins
// it leaves here bc w/o business value it's pointless
const DEFAULT_INTERVAL: number = 30;
const getCorrectInterval = (
  intervalSettings: number | undefined | null
): number => {
  return !intervalSettings || intervalSettings < DEFAULT_INTERVAL
    ? DEFAULT_INTERVAL
    : intervalSettings;
};

interface VenueOrderSettingsStateModel
  extends Modify<
    Omit<VenueOrderSettings, 'preparation'>,
    {
      averagePreparationTime: number;
      curbside: OrderSettings | null;
      delivery: DeliverySettings | null;
      dinein: DineInSettings | null;
      general: GeneralSettings | null;
      pacing: PacingSettings | null;
      takeout: OrderSettings | null;
    }
  > {}
export const isDeliveryMethodActive = (
  state: VenueOrderSettingsStateModel,
  method: DeliveryMethod
): boolean =>
  (state.general?.availableSalesOptions.includes(method) &&
    state[method]?.active) ||
  false;

const defaults: VenueOrderSettingsStateModel = {
  averagePreparationTime: 30,
  curbside: null,
  delivery: null,
  dinein: null,
  general: null,
  pacing: null,
  takeout: null,
};
@State<VenueOrderSettingsStateModel>({
  name: 'venueOrderSettings',
  defaults,
})
@Injectable()
export class VenueOrderSettingsState {
  @Selector()
  static availableDeliveryMethods({
    general,
  }: VenueOrderSettingsStateModel): DeliveryMethod[] {
    return general?.availableSalesOptions || [];
  }

  @Selector()
  static allowScheduledOrders({
    general,
  }: VenueOrderSettingsStateModel): boolean {
    return general?.settings?.allowScheduledOrders || false;
  }

  @Selector()
  static availablePaymentMethods({
    general,
  }: VenueOrderSettingsStateModel): PaymentMethods[] {
    const paymentMethods: PaymentMethods[] = [];

    for (let method of general?.paymentTypes || []) {
      switch (method) {
        case PaymentMethodsBE.CardPresent:
        case PaymentMethodsBE.Card:
          paymentMethods.push(PaymentMethods.card);
          break;
        case PaymentMethodsBE.GooglePay:
          paymentMethods.push(PaymentMethods.google_pay);
          break;
        case PaymentMethodsBE.ApplePay:
          paymentMethods.push(PaymentMethods.apple_pay);
          break;
        case PaymentMethodsBE.CardNotPresent:
          paymentMethods.push(PaymentMethods.gift_card);
          break;
      }
    }

    return uniq(paymentMethods) || [];
  }

  @Selector()
  static activeDeliveryMethods(
    state: VenueOrderSettingsStateModel
  ): ActiveDeliverySettings[] {
    if (!state.general?.availableSalesOptions?.length) {
      return [];
    }

    const orderedDeliveryMethods: DeliveryMethod[] = [
      DeliveryMethod.Delivery,
      DeliveryMethod.Takeout,
      DeliveryMethod.Curbside,
    ].filter((orderMethod: DeliveryMethod) =>
      state?.general?.availableSalesOptions.includes(orderMethod)
    );

    return orderedDeliveryMethods.map(
      (method: DeliveryMethod) =>
        ({
          active: state[method]?.active,
          method,
        } as ActiveDeliverySettings)
    );
  }

  @Selector()
  static defaultDeliveryMethod(
    state: VenueOrderSettingsStateModel
  ): DeliveryMethod {
    if (!state.general?.availableSalesOptions?.length) {
      return DeliveryMethod.Delivery;
    }

    switch (true) {
      // need to have them in order
      case isDeliveryMethodActive(state, DeliveryMethod.Delivery):
        return DeliveryMethod.Delivery;
      case isDeliveryMethodActive(state, DeliveryMethod.Takeout):
        return DeliveryMethod.Takeout;
      case isDeliveryMethodActive(state, DeliveryMethod.Curbside):
        return DeliveryMethod.Curbside;
      default:
        return DeliveryMethod.Delivery;
    }
  }

  static isDeliveryMethodActive(method: DeliveryMethod) {
    return createSelector(
      [VenueOrderSettingsState],
      (state: VenueOrderSettingsStateModel) =>
        isDeliveryMethodActive(state, method)
    );
  }

  @Selector([VenueOrderSettingsState.activeDeliveryMethods])
  static isThereAnyAvailableOrderMethod(
    _: VenueOrderSettingsStateModel,
    activeDeliveryMethods: ActiveDeliverySettings[]
  ): boolean {
    return !!activeDeliveryMethods.find(({ active }) => active);
  }

  @Selector([VenueOrderSettingsState.availableDeliveryMethods])
  static availableOrderTypes(
    _: VenueOrderSettingsStateModel,
    availableSalesOptions: DeliveryMethod[]
  ): DeliveryType[] {
    return [OrderType.DineIn, ...availableSalesOptions] as DeliveryType[];
  }

  @Selector()
  static scheduleSettings({
    averagePreparationTime,
    pacing,
  }: VenueOrderSettingsStateModel): ScheduleSettings {
    const rangeStep: number = getCorrectInterval(pacing?.intervalInMinutes);
    const timeInMinutes: number = getCorrectInterval(
      secondsToMins(averagePreparationTime)
    );

    return {
      asapMinTime: timeInMinutes,
      asapMaxTime: timeInMinutes + rangeStep,
      rangeStep,
    };
  }

  @Selector()
  static schedule({
    pacing,
  }: VenueOrderSettingsStateModel): ServiceOpeningHours[] {
    return pacing?.schedule || [];
  }

  @Selector([VenueState.openingHours])
  static scheduleRange(
    { pacing, general }: VenueOrderSettingsStateModel,
    openingHours: OperatingHours[]
  ): ScheduleRange[] {
    const shouldCalculateRangeBasedOnOperatingHours: boolean =
      pacing?.sameAsOpeningHours || !pacing?.schedule?.length;
    const allowScheduledOrders: boolean =
      general?.settings?.allowScheduledOrders || false;

    return shouldCalculateRangeBasedOnOperatingHours
      ? getScheduleRangeBasedOnVenueOperatingHours(
          openingHours,
          allowScheduledOrders
        )
      : getScheduleRangeBasedOnPacingSchedule(
          pacing?.schedule || [],
          allowScheduledOrders
        );
  }

  @Action(UpdateVenueOrderSettings)
  getVenueConfig(
    { patchState }: StateContext<VenueOrderSettingsStateModel>,
    { venueOrderSettings }: UpdateVenueOrderSettings
  ) {
    patchState(
      venueOrderSettings
        ? {
            ...venueOrderSettings,
            averagePreparationTime:
              venueOrderSettings.preparation.averagePreparationTime,
          }
        : { ...defaults }
    );
  }
}

import { CurrencyPipe } from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Inject,
} from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';

import { Store } from '@ngxs/store';
import { combineLatest, filter, map } from 'rxjs';

import { ProfileState } from 'src/app/_shared/_ngxs/profile.state';
import { CartState } from 'src/app/_shared/_ngxs/cart.state';
import { SessionState } from 'src/app/_shared/_ngxs/authentication.state';
import { OrderDataState } from 'src/app/_shared/_ngxs/order-data.state';
import {
  ClearGuestUserData,
  CreateDeliveryQuote,
  SelectCardForOrder,
  SelectGiftCardForOrder,
  CalculateCheckAndCreateDeliveryQuote,
  SetIsSearchingDriver,
} from 'src/app/_shared/_ngxs/cart.actions';
import {
  ClearPurchasedGiftCards,
  CreateDineInOrder,
  CreateGiftCards,
  CreateNotDineInOrder,
} from 'src/app/_shared/_ngxs/order.actions';
import {
  GetAddressBook,
  GetProfileGiftCards,
} from 'src/app/_shared/_ngxs/profile.actions';
import { UpdateOrderType } from 'src/app/_shared/_ngxs/order-data.actions';

import { DeliveryMethod, OrderType } from 'src/app/_shared/_enums/order.enum';
import { PaymentMethods } from 'src/app/_shared/_enums/payment-methods.enum';

import { TEST_ID } from 'src/app/_shared/_constants/e2e-ids.constants';

import { untilDestroyed } from 'src/app/_shared/_utils/until-destroyed';
import { DeviceService } from 'src/app/_services/device.service';

@Component({
  selector: 'rs-pay-now-popup',
  templateUrl: './pay-now-popup.component.html',
  styleUrls: ['./pay-now-popup.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [CurrencyPipe],
})
export class PayNowPopupComponent {
  public isMobileView: boolean = false;
  public showDelivery: boolean = false;
  public isGiftCardPopup: boolean = false;
  public isSearchingDriver: boolean = false;

  private searchingDriverApiCallsCount = 0;
  private searchingDriverIntervalId: NodeJS.Timeout | undefined;

  private destroyed$ = untilDestroyed();
  public readonly id = TEST_ID;

  constructor(
    private deviceService: DeviceService,
    private changeDetectorRef: ChangeDetectorRef,
    private store: Store,
    private router: Router,
    @Inject(MAT_DIALOG_DATA) public isGiftCard: boolean
  ) {
    this.isGiftCardPopup = isGiftCard;
  }

  ngOnInit(): void {
    this.store.dispatch([
      new SetIsSearchingDriver(false, false),
      new CalculateCheckAndCreateDeliveryQuote(true, false),
    ]);

    this.setInitOrderData();
    this.subscribeOnDeviceWidthChange();
    this.subscribeOnGiftCardPaymentSelection();
    this.subscribeOnSearchingDriver();
    this.receiveAddressBook();

    this.store.dispatch(new ClearGuestUserData());
  }

  ngOnDestroy(): void {
    this.store.dispatch(new SetIsSearchingDriver(false, false));
  }

  private subscribeOnGiftCardPaymentSelection(): void {
    combineLatest([
      this.store.select(SessionState.isLoggedIn),
      this.store.select(CartState.paymentMethod),
      this.store.select(ProfileState.giftCards),
    ])
      .pipe(
        filter(
          ([isLoggedIn, paymentMethod, _]) =>
            !!isLoggedIn && paymentMethod == PaymentMethods.gift_card
        ),
        map(([_, __, giftCards]) => giftCards),
        this.destroyed$()
      )
      .subscribe(giftCards =>
        this.store.dispatch(new SelectGiftCardForOrder(giftCards[0]))
      );
  }

  public payForOrder(): void {
    if (this.isGiftCardPopup) {
      this.store.dispatch([
        new ClearPurchasedGiftCards(),
        new CreateGiftCards(),
      ]);
    } else {
      const orderType = this.store.selectSnapshot(OrderDataState.orderType)!;

      this.store.dispatch(
        orderType === OrderType.DineIn
          ? new CreateDineInOrder()
          : new CreateNotDineInOrder()
      );
    }
  }

  private receiveAddressBook(): void {
    if (!this.store.selectSnapshot(SessionState.isLoggedIn)) {
      return;
    }

    this.store.dispatch([new GetAddressBook(), new GetProfileGiftCards()]);
  }

  private setInitOrderData(): void {
    const isDineIn =
      this.router.url.includes('/dine-in/') ||
      this.store.selectSnapshot(OrderDataState.orderType) === OrderType.DineIn;

    const actions: unknown[] = [
      new SelectCardForOrder(null),
      new SelectGiftCardForOrder(null),
    ];

    isDineIn && actions.push(new UpdateOrderType(OrderType.DineIn));

    this.store.dispatch(actions);
    this.showDelivery = !isDineIn && !this.isGiftCardPopup;
  }

  private subscribeOnDeviceWidthChange(): void {
    combineLatest([
      this.deviceService.isLaptop(),
      this.deviceService.isDesktop(),
    ])
      .pipe(this.destroyed$())
      .subscribe(([isLaptop, isDesktop]) => {
        this.isMobileView = !(isLaptop || isDesktop);
        this.changeDetectorRef.markForCheck();
      });
  }

  private subscribeOnSearchingDriver(): void {
    combineLatest([
      this.store.select(OrderDataState.orderType),
      this.store.select(CartState.isSearchingDriver),
    ])
      .pipe(this.destroyed$())
      .subscribe(([orderType, isSearchingDriver]) => {
        this.isSearchingDriver = isSearchingDriver;

        /* If after quote api call driver is not found we need to call same api 5 times after 10sec
           https://rockspoon.atlassian.net/browse/CORE-2718
        */
        if (
          this.searchingDriverApiCallsCount === 0 &&
          this.isSearchingDriver &&
          orderType === DeliveryMethod.Delivery
        ) {
          this.searchingDriverIntervalId = setInterval(() => {
            this.runQuote();
          }, 10000);
        }
      });
  }

  private runQuote(): void {
    const orderType = this.store.selectSnapshot(OrderDataState.orderType);

    if (
      ++this.searchingDriverApiCallsCount > 5 ||
      !this.isSearchingDriver ||
      orderType !== DeliveryMethod.Delivery
    ) {
      clearInterval(this.searchingDriverIntervalId);

      this.searchingDriverApiCallsCount = 0;
      this.searchingDriverIntervalId = undefined;
    } else {
      this.store.dispatch(
        new CreateDeliveryQuote(true, {
          searchingForDriver: true,
          isLastSearchingDriverApiCall: this.searchingDriverApiCallsCount === 5,
        })
      );
    }
  }
}
